import {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
  useMemo,
} from 'react';
// import { Redirect } from 'react-router-dom';
import {
  Row,
  Col,
  Table,
  Modal,
  Container,
  Form,
  Button,
  Card,
  CardGroup,
  Alert,
  ListGroup,
} from 'react-bootstrap';
import { useHotkeys } from 'react-hotkeys-hook';
import { useInView } from 'react-intersection-observer';
import {
  useEvalConfig,
  SessionSubscriptionManager,
  useJudgingEnabled,
  useSessionByKey,
  useTeamsByAthleteId,
  useCombinedSessions,
  useCombinedTeamsByAthleteIds,
} from './hooks';
import {
  gearIcon,
  pdfIcon,
  chevronUpIcon,
  chevronDownIcon,
  editIcon,
  checkIcon,
} from '../helpers/icons';
import { useQuery, NetworkStatus } from '@apollo/client';
import GetFullSession from '../../apollo/queries/GetFullSession.graphql';
import {
  // useScoreSheet,
  useCombineScoreSheet,
  convertMillipointsToDisplay,
  prettyTeamScoring,
  scoresheetFormat,
} from '../../utilities/scoring';
import officialscoresheetStyles from './officialscoresheet.module.css';
import { format as dateFormat } from 'date-fns';
import {
  GYMapparatus,
  fullNameToInitialLastName,
  abbvToApparatusName,
  b64toBlob,
  displayName,
} from '../../utilities/conversions';
import { POLLING_INTERVAL } from '../../utilities/constants';
import { useInterval } from '../../utilities/hooks';
import { formatInTimeZone } from 'date-fns-tz';
import {
  GenderType,
  GymApparatus,
  ScoreType,
  SessionTeamScoreType,
} from '../../models';
import { alertActions } from '../../redux/_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { debounce } from 'lodash';
import Header from '../helpers/header';
import MenuBubble from '../helpers/menububble';
import UserBubble from '../helpers/userbubble';
import ToastStack from './toaststack';
import axios from 'axios';
import { useQueryParamsAdv } from '../../utilities/query';
import { UpdateSession } from '../../apollo/mutations/UpdateSession.graphql';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ncaaHeadCoaches } from '../../data/coaches';
import FullscreenLoading from '../helpers/fullscreenloading';
// import { hide } from '@popperjs/core';

const scoresheetPDFAPI =
  'https://rnqb2biyxnvc3nnex3osqzb2hi0jqbra.lambda-url.us-west-2.on.aws/session/';

const coachData = {
  ...ncaaHeadCoaches,
  C1: {
    name: 'Head Coach',
  },
  C2: {
    name: 'Head Coach',
  },
};

function ScrollShadow({ pct }) {
  const { scrollShadow } = officialscoresheetStyles;
  return <div className={scrollShadow} style={{ width: `${pct}%` }}></div>;
}

function Footer({ hide }) {
  const { toastFooter } = officialscoresheetStyles;

  return !hide ? (
    <div className={toastFooter}>
      <ToastStack />
    </div>
  ) : null;
}

function ScoreSheetSetupButton({ handler }) {
  const { scoreSheetSetupButton } = officialscoresheetStyles;

  return (
    <div className={scoreSheetSetupButton}>
      <Button variant="outline-secondary" onClick={handler}>
        {gearIcon}
      </Button>
    </div>
  );
}

function ScoreSheetPrintButton({ handler }) {
  const { scoreSheetPrintButton } = officialscoresheetStyles;

  return (
    <div className={scoreSheetPrintButton}>
      <Button variant="outline-secondary" onClick={handler}>
        {pdfIcon}
      </Button>
    </div>
  );
}

function ScoreSheetPrevButton({ disabled, handler }) {
  const { scoreSheetUpButton } = officialscoresheetStyles;

  return (
    <div className={scoreSheetUpButton}>
      <Button disabled={disabled} variant="outline-secondary" onClick={handler}>
        {chevronUpIcon}
      </Button>
    </div>
  );
}

function ScoreSheetNextButton({ disabled, handler }) {
  const { scoreSheetDownButton } = officialscoresheetStyles;

  return (
    <div className={scoreSheetDownButton}>
      <Button disabled={disabled} variant="outline-secondary" onClick={handler}>
        {chevronDownIcon}
      </Button>
    </div>
  );
}

const exportPDF = ({
  sessionId,
  sessionName,
  dispatch,
  timezone,
  combineKey,
  combineKeys,
  hideJudgeNames: noJudge,
  lineupVerification: lineupVerificationMode,
  singleSheet: singleSheetOnly,
}) => {
  const tz = timezone ? `&tz=${timezone}` : '';
  // Support both single combineKey (legacy) and multiple combineKeys
  const combine =
    combineKeys && combineKeys.length > 0
      ? `&combinekeys=${combineKeys.join(',')}`
      : combineKey
      ? `&combinekey=${combineKey}`
      : '';
  const hideJudge = noJudge ? '&nojudge=1' : '';
  const singleSheet = singleSheetOnly ? '&singlesheet=1' : '';
  const lineupVerification = lineupVerificationMode
    ? '&lineupverification=1'
    : '';
  const timestamp = formatInTimeZone(
    Date.now(),
    timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    "M'/'dd'/'yyyy @ hh:mm:ss aaa zzz"
  );
  const fileName = `${sessionName} - ${sessionId} @ ${timestamp}.pdf`;

  dispatch(alertActions.notification('Generating Scoresheet PDF...', false));

  axios({
    url: `${scoresheetPDFAPI}${sessionId}/?type=team${tz}${combine}${hideJudge}${lineupVerification}${singleSheet}`,
    method: 'GET',
    responseType: 'text',
    responseEncoding: 'base64',
  }).then((response) => {
    // console.log(response);
    const b64Blob = b64toBlob(response.data, 'application/pdf');
    const urlBlob = window.URL.createObjectURL(
      new Blob([b64Blob], { type: 'application/pdf' })
    );
    const element = document.createElement('a');
    element.setAttribute('href', urlBlob);
    element.setAttribute('download', fileName);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    dispatch(alertActions.success('Scoresheet PDF Completed.'));
  });
};

const PdfOptions = {
  ALL: 'ALL',
  CURRENT: 'CURRENT',
  OFFICIAL: 'OFFICIAL',
  RANKINGS: 'RANKINGS',
  CUSTOM: 'CUSTOM',
};

// const SCORESHEET_TYPES = {
//   TEAM: 'TEAM',
//   SUMMARY: 'SUMMARY',
//   CHAMPIONSHIPS: 'CHAMPIONSHIPS',
//   APPARATUS: 'APPARATUS',
//   AA: 'AA',
//   BREAKDOWN: 'BREAKDOWN',
// };

const ScoreSheetContext = createContext(null);

function ScoreSheetConfigContext(props) {
  const {
    format,
    session,
    noJudge,
    lineupVerificationMode,
    singleSheet,
  } = props;
  const [multiDualSheets, setMultiDualSheets] = useState(
    !!format?.isMakeMultiDuals
  );
  const [lineupVerification, setLineupVerification] = useState(
    lineupVerificationMode
  );
  const [showExhibitions, setShowExhibitions] = useState(false);
  const [combineSessions, setCombineSessions] = useState(false);
  const [singleSheetOnly, setSingleSheetOnly] = useState(singleSheet);
  const [championshipsStyle, setChampionshipsStyle] = useState(
    !!format?.isChampionships
  );
  const [hideJudgeNames, setHideJudgeNames] = useState(noJudge);
  const [pdfOption, setPdfOption] = useState(PdfOptions.ALL);
  const teamScoring = session.teamScoring;

  const toggleMultiDualSheets = () => setMultiDualSheets(!multiDualSheets);
  const toggleLineupVerification = () =>
    setLineupVerification(!lineupVerification);
  const toggleShowExhibitions = () => setShowExhibitions(!showExhibitions);
  const toggleCombineSessions = () => setCombineSessions(!combineSessions);
  const toggleSingleSheetOnly = () => setSingleSheetOnly(!singleSheetOnly);
  const toggleHideJudgeNames = () => setHideJudgeNames(!hideJudgeNames);
  const toggleChampionshipsStyle = () =>
    setChampionshipsStyle(!championshipsStyle);

  const config = {
    multiDualSheets,
    lineupVerification,
    showExhibitions,
    combineSessions,
    singleSheetOnly,
    hideJudgeNames,
    championshipsStyle,
    pdfOption,
    toggleMultiDualSheets,
    toggleLineupVerification,
    toggleShowExhibitions,
    toggleCombineSessions,
    toggleSingleSheetOnly,
    toggleHideJudgeNames,
    toggleChampionshipsStyle,
    setPdfOption,
    teamScoring,
  };

  return (
    <ScoreSheetContext.Provider value={config}>
      {props.children}
    </ScoreSheetContext.Provider>
  );
}

// function CombineSessionContext(props) {}

function ScoreSheetConfig(props) {
  const {
    teams,
    setTeamAIndex,
    setTeamBIndex,
    teamAIndex,
    teamBIndex,
    location,
    attendance,
    setLocation,
    setAttendance,
    sessionId,
    sessionVersion,
    setShow,
    setAltTeamScoring,
    altTeamScoring,
    setCombineKey,
    setCombineSessions,
    // combineSessions,
    combineSession,
    combineKey,
    combineLoaded,
    combinedSessionsLoaded,
    setCombinedSessionsLoaded,
    combineKeys,
    setCombineKeys,
    combinedSessions,
  } = props;

  const [combineURL, setCombineURL] = useState('');
  const { scoreSheetSetup, row, customControl } = officialscoresheetStyles;
  const [updateSession] = useMutation(UpdateSession);
  const debouncedUpdate = useCallback(debounce(updateSessionDetails, 1000), [
    sessionVersion,
  ]);
  const dispatch = useDispatch();
  const judgingEnabled = useJudgingEnabled();
  const config = useContext(ScoreSheetContext);
  const { alert } = useSelector((state) => state);

  const {
    multiDualSheets,
    lineupVerification,
    // showExhibitions,
    // combineSessions,
    singleSheetOnly,
    hideJudgeNames,
    // pdfOption,
    toggleMultiDualSheets,
    toggleLineupVerification,
    // toggleShowExhibitions,
    // toggleCombineSessions,
    toggleSingleSheetOnly,
    toggleHideJudgeNames,
    // setPdfOption,
    teamScoring,
  } = config;

  const validationSchema = Yup.object().shape({
    multiDualSheets: Yup.boolean(),
    lineupVerification: Yup.boolean(),
    showExhibitions: Yup.boolean(),
    combineSessions: Yup.boolean(),
    singleSheetOnly: Yup.boolean(),
    hideJudgeNames: Yup.boolean(),
    pdfOption: Yup.string(),
  });

  const getDefault = (original) => {
    // console.log(original);
    const result = {
      multiDualSheets: !!original?.multiDualSheets,
      lineupVerification: !!original?.lineupVerification,
      showExhibitions: !!original?.showExhibitions,
      combineSessions: !!original?.combineSessions,
      singleSheetOnly: !!original?.singleSheetOnly,
      hideJudgeNames: !!original?.hidgeJudgeNames,
      pdfOption: original?.pdfOption || PdfOptions.ALL,
      teamScoring: teamScoring,
    };
    return result;
  };

  const { setFieldValue, /* handleSubmit, handleChange, */ values } = useFormik(
    {
      initialValues: getDefault(config),
      validationSchema,
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit(values) {},
    }
  );

  function updateSessionDetails({ newLocation, newAttendance }) {
    const input = {
      id: sessionId,
      _version: sessionVersion,
    };

    if (newLocation) {
      input['location'] = newLocation;
    }

    if (newAttendance) {
      input['attendance'] = newAttendance;
    }

    return updateSession({ variables: { input } })
      .then(() => {
        if (newLocation) {
          console.log('Session update successful for location: ', newLocation);
          dispatch(alertActions.notification('Session location updated.'));
        }
        if (newAttendance) {
          console.log(
            'Session update successful for attendance: ',
            newAttendance
          );
          dispatch(alertActions.notification('Session attendance updated.'));
        }
      })
      .catch((err) => {
        console.log('Session update failed: ', err);
      });
  }

  function handleSetLocation(e) {
    setLocation(e.target.value);
    debouncedUpdate({ newLocation: e.target.value, newAttendance: null });
  }

  function handleSetAttendance(e) {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^\d]/g, '');
    setAttendance(inputValue);
    debouncedUpdate({ newAttendance: inputValue, newLocation: null });
  }

  function handleAddCombineSession(e) {
    e.preventDefault();

    const sessionKey = combineURL.split('https://virti.us/session?s=')?.[1];

    if (!sessionKey || sessionKey.length !== 10) {
      dispatch(alertActions.error('Invalid combine session URL.'));
      return;
    }

    // Check if this session is already added
    if (combineKeys.includes(sessionKey)) {
      dispatch(alertActions.error('This session is already added.'));
      return;
    }

    // Check if we've reached the maximum of 3 additional sessions
    const additionalSessionCount = (combineKey ? 1 : 0) + combineKeys.length;
    if (additionalSessionCount >= 3) {
      dispatch(
        alertActions.error(
          'Maximum of 3 additional sessions reached. Please remove a session before adding another.'
        )
      );
      return;
    }

    // Show loading alert
    dispatch(
      alertActions.notification(`Loading combine session: ${combineURL}`, false)
    );

    // Add to the array of keys
    setCombineKeys([...combineKeys, sessionKey]);
    setCombineURL(''); // Clear the input field
  }

  function handleRemoveCombineSession(index) {
    // Store the key being removed
    const removedKey = combineKeys[index];

    // Create a new array without the removed session
    const newCombineKeys = [...combineKeys];
    newCombineKeys.splice(index, 1);

    // Update the keys state
    setCombineKeys(newCombineKeys);

    // Also remove from the loaded tracking object
    const newCombinedSessionsLoaded = { ...combinedSessionsLoaded };
    delete newCombinedSessionsLoaded[removedKey];
    setCombinedSessionsLoaded(newCombinedSessionsLoaded);

    dispatch(alertActions.notification('Combine session removed.'));
  }

  function handleResetCombineSessions() {
    setCombineKeys([]);
    setCombineSessions([]);
    setCombineURL('');
    setCombineKey('');
    // Clear the loaded sessions tracking
    setCombinedSessionsLoaded({});
    dispatch(alertActions.notification('All combine sessions reset.'));
  }

  // For backward compatibility
  // function handleLoadCombineSession(e) {
  //   e.preventDefault();

  //   const sessionKey = combineURL.split('https://virti.us/session?s=')?.[1];

  //   if (!sessionKey || sessionKey.length !== 10) {
  //     dispatch(alertActions.error('Invalid combine session URL.'));
  //     return;
  //   }

  //   // Check if we've reached the maximum of 3 additional sessions
  //   const additionalSessionCount = combineKeys.length;
  //   if (additionalSessionCount >= 3) {
  //     dispatch(alertActions.error('Maximum of 3 additional sessions reached. Please remove a session before adding another.'));
  //     return;
  //   }

  //   dispatch(alertActions.notification(`Loading combine session: ${combineURL}`, false));
  //   setCombineKey(sessionKey);
  // }

  function handleResetCombineSession() {
    // Get the key being removed
    const keyBeingRemoved = combineKey;
    setCombineKey('');
    setCombineURL('');
    setCombineSessions([]);

    // Update the loaded state to reflect the removal
    if (keyBeingRemoved) {
      const newCombinedSessionsLoaded = { ...combinedSessionsLoaded };
      delete newCombinedSessionsLoaded[keyBeingRemoved];
      setCombinedSessionsLoaded(newCombinedSessionsLoaded);
    }

    dispatch(alertActions.notification('Combine session reset.'));
  }

  // console.log(combinedSessions, combinedSessionsLoaded, combineKey, combineKeys);

  const setupBody = () => {
    return (
      <Container className={scoreSheetSetup}>
        {!alert.clear ? (
          <Row style={{ margin: '0 0 1rem 0' }}>
            <Alert
              dismissible
              onClose={() => dispatch(alertActions.clear())}
              variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
              style={{ textAlign: 'center', width: '100%' }}
            >
              {alert.message}
            </Alert>
          </Row>
        ) : null}
        <CardGroup>
          <Card style={{ width: '33%' }}>
            <Card.Header>Header</Card.Header>
            <Card.Body>
              <Row className={row}>
                <Col>
                  <Card.Subtitle>Competition Details (Required)</Card.Subtitle>
                  <Row>
                    <Col>
                      <label>Location:</label>
                      <Form.Control
                        plaintext
                        name="location"
                        type="text"
                        autoComplete="off"
                        value={location}
                        onChange={handleSetLocation}
                        placeholder="Enter location..."
                        disabled={!judgingEnabled}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Attendance:</label>
                      <Form.Control
                        plaintext
                        name="attendance"
                        type="text"
                        autoComplete="off"
                        value={
                          attendance.length === 0
                            ? ''
                            : Number(attendance).toLocaleString()
                        }
                        onChange={handleSetAttendance}
                        placeholder="Enter attendance..."
                        disabled={!judgingEnabled}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={row}>
                <Col>
                  <Card.Subtitle>
                    Custom Team Selection (Optional)
                  </Card.Subtitle>
                  <Row>
                    <Col>
                      <label>Home:</label>
                      <Form.Control
                        as="select"
                        value={teamAIndex ?? 'Select...'}
                        disabled={!singleSheetOnly}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={(e) => {
                          setTeamAIndex(e.target.value);
                        }}
                      >
                        <option value="Select...">Select...</option>
                        {teams.map((t, i) => {
                          return (
                            <option key={`${t?.name}_home_${i}`} value={i}>
                              {t?.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Visitor:</label>
                      <Form.Control
                        as="select"
                        value={teamBIndex ?? 'Select...'}
                        disabled={!singleSheetOnly}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={(e) => {
                          setTeamBIndex(e.target.value);
                        }}
                      >
                        <option value="Select...">Select...</option>
                        {teams.map((t, i) => {
                          return (
                            <option key={`${t?.name}_visitor_${i}`} value={i}>
                              {t?.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card style={{ width: '33%' }}>
            <Card.Header>Settings</Card.Header>
            <Card.Body>
              <Row className={row}>
                <Col>
                  <Row>
                    <Col>
                      <Card.Subtitle>Additional Forms</Card.Subtitle>
                      <Form.Check
                        type="checkbox"
                        label="Lineup Verification"
                        custom
                        id="checkLineupVerification"
                        className={customControl}
                        checked={lineupVerification}
                        onChange={toggleLineupVerification}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Multi Dual Scoresheets"
                        custom
                        disabled={teams.length < 3}
                        id="checkMultiDual"
                        className={customControl}
                        checked={multiDualSheets}
                        onChange={toggleMultiDualSheets}
                      />
                      {/*<Form.Check
                        type="checkbox"
                        label="Combine Sessions"
                        custom
                        id="checkCombine"
                        className={customControl}
                        onChange={(e) => {
                          setFieldValue(
                            'combineSessions',
                            !values.combineSessions
                          );
                        }}
                      />*/}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Card.Subtitle>Display</Card.Subtitle>
                      <Form.Check
                        type="checkbox"
                        label="Single Sheet Only"
                        custom
                        id="checkSingle"
                        className={customControl}
                        checked={singleSheetOnly}
                        onChange={toggleSingleSheetOnly}
                      />
                      <Form.Check
                        type="checkbox"
                        label="Hide Judge Names"
                        custom
                        id="checkJudge"
                        className={customControl}
                        checked={hideJudgeNames}
                        onChange={toggleHideJudgeNames}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={row}>
                <Col>
                  <Row>
                    <Col>
                      <Card.Subtitle>PDF</Card.Subtitle>
                      <div key="pdfRadio">
                        <Form>
                          <fieldset>
                            <Form.Check
                              type="radio"
                              label="All Pages"
                              custom
                              name="pdfGroup"
                              id="pdfRadio-1"
                              className={customControl}
                              checked={values.pdfOption === PdfOptions.ALL}
                              onChange={(e) => {
                                setFieldValue('pdfOption', PdfOptions.ALL);
                              }}
                            />
                            {/*<Form.Check
                              type="radio"
                              label="Current Page Only"
                              custom
                              name="pdfGroup"
                              id="pdfRadio-2"
                              className={customControl}
                              checked={values.pdfOption === PdfOptions.CURRENT}
                              onChange={(e) => {
                                setFieldValue('pdfOption', PdfOptions.CURRENT);
                              }}
                            />
                            <Form.Check
                              type="radio"
                              label="Official Scoresheets Only"
                              custom
                              name="pdfGroup"
                              id="pdfRadio-3"
                              className={customControl}
                              checked={values.pdfOption === PdfOptions.OFFICIAL}
                              onChange={(e) => {
                                setFieldValue('pdfOption', PdfOptions.OFFICIAL);
                              }}
                            />
                            <Form.Check
                              type="radio"
                              label="Rankings/Results"
                              custom
                              name="pdfGroup"
                              id="pdfRadio-4"
                              className={customControl}
                              checked={values.pdfOption === PdfOptions.RANKINGS}
                              onChange={(e) => {
                                setFieldValue('pdfOption', PdfOptions.RANKINGS);
                              }}
                            />
                            <Form.Check
                              type="radio"
                              label="Custom"
                              custom
                              name="pdfGroup"
                              id="pdfRadio-5"
                              className={customControl}
                              checked={values.pdfOption === PdfOptions.CUSTOM}
                              onChange={(e) => {
                                setFieldValue('pdfOption', PdfOptions.CUSTOM);
                              }}
                            />
                            */}
                          </fieldset>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={row}>
                <Col>
                  <Card.Subtitle>Team Score (Ties)</Card.Subtitle>
                  <div key="teamScoreAlgos">
                    <label>Options:</label>
                    <Form.Control
                      as="select"
                      value={
                        altTeamScoring
                          ? prettyTeamScoring(altTeamScoring)
                          : `${prettyTeamScoring(values.teamScoring)} (Default)`
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        const selectedMethod =
                          SessionTeamScoreType[
                            Object.keys(SessionTeamScoreType)[
                              e.target.selectedIndex - 1
                            ]
                          ];

                        const newValue =
                          values.teamScoring === selectedMethod
                            ? null
                            : selectedMethod;

                        setAltTeamScoring(newValue);
                      }}
                    >
                      <option value="Select..." hidden disabled>
                        Select...
                      </option>
                      {Object.values(SessionTeamScoreType).map((type, i) => {
                        return (
                          <option key={i}>
                            {prettyTeamScoring(type)}
                            {values.teamScoring === type ? ' (Default)' : ''}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </div>
                </Col>
              </Row>
              <Row className={row}>
                <Col>
                  <Card.Subtitle>
                    Combine Sessions{' '}
                    {(Object.keys(combinedSessionsLoaded).length > 0 ||
                      (combineKey && combineLoaded)) &&
                    (combineKeys.length > 0 || combineKey) ? (
                      <strong>
                        (LOADED:{' '}
                        {Object.keys(combinedSessionsLoaded).length +
                          (combineKey && combineLoaded ? 1 : 0)}
                        ) <span className="green">{checkIcon}</span>
                      </strong>
                    ) : null}
                  </Card.Subtitle>
                  <div>
                    <label>Add Session:</label>
                    <Form>
                      <Form.Control
                        plaintext
                        name="combineSession"
                        type="text"
                        autoComplete="off"
                        value={combineURL || ''}
                        onChange={(e) => setCombineURL(e.target.value)}
                        placeholder="Enter session URL..."
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            handleAddCombineSession(event);
                          }
                        }}
                      />
                      <Row>
                        <Col style={{ padding: '1rem' }}>
                          <Button
                            variant="light"
                            style={{ width: '100%' }}
                            onClick={handleResetCombineSessions}
                          >
                            Reset All
                          </Button>
                        </Col>
                        <Col style={{ padding: '1rem' }}>
                          <Button
                            variant="outline-secondary"
                            style={{ width: '100%' }}
                            onClick={handleAddCombineSession}
                          >
                            Add
                          </Button>
                        </Col>
                      </Row>
                    </Form>

                    {/* Display list of combined sessions */}
                    {(combineKeys.length > 0 ||
                      (combineKey && combineLoaded)) && (
                      <div style={{ marginTop: '1rem' }}>
                        <label>
                          Combined Sessions:{' '}
                          <span className="text-muted">(Max 3 total)</span>
                        </label>
                        <ListGroup>
                          {combineKey && (
                            <ListGroup.Item className="d-flex justify-content-between align-items-center">
                              <div>
                                {!combineLoaded ? (
                                  <span className="text-muted">
                                    Loading session...
                                  </span>
                                ) : combineSession?.session ? (
                                  <>
                                    <div>
                                      {combineSession.session.name ||
                                        'Session 1'}
                                    </div>
                                    <small className="text-muted">
                                      <a
                                        href={`https://virti.us/session?s=${combineKey}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        https://virti.us/session?s={combineKey}
                                      </a>
                                    </small>
                                  </>
                                ) : (
                                  'Loading failed'
                                )}
                              </div>
                              <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={handleResetCombineSession}
                              >
                                Remove
                              </Button>
                            </ListGroup.Item>
                          )}
                          {combineKeys.map((key, index) => (
                            <ListGroup.Item
                              key={index}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <div>
                                {!combinedSessions[index] ? (
                                  <span className="text-muted">
                                    Loading session...
                                  </span>
                                ) : (
                                  <>
                                    <div>
                                      {combinedSessions[index]?.name ||
                                        `Session ${
                                          index + 1 + (combineKey ? 1 : 0)
                                        }`}
                                    </div>
                                    <small className="text-muted">
                                      <a
                                        href={`https://virti.us/session?s=${key}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        https://virti.us/session?s={key}
                                      </a>
                                    </small>
                                  </>
                                )}
                              </div>
                              <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={() =>
                                  handleRemoveCombineSession(index)
                                }
                              >
                                Remove
                              </Button>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                        <div
                          className="text-muted mt-2"
                          style={{ fontSize: '0.8rem' }}
                        >
                          {(combineKey ? 1 : 0) + combineKeys.length} of 3
                          additional sessions used
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </CardGroup>
      </Container>
    );
  };

  const footer = () => {
    return (
      <Row className="vCenter" style={{ margin: '0 auto' }}>
        <Button
          variant="outline-secondary"
          className="formButton"
          onClick={() => setShow(false)}
        >
          Close
        </Button>
      </Row>
    );
  };

  const headerMsg = () => {
    return <span>Scoresheet Setup</span>;
  };
  return (
    <>
      <Modal.Header className="setupHeader">{headerMsg()}</Modal.Header>
      <Modal.Body className="setupForm">{setupBody()}</Modal.Body>
      <Modal.Footer className="setupFooter">{footer()}</Modal.Footer>
    </>
  );
}

function ScoreSheetSetup(props) {
  const { show, setShow } = props;

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      centered
      dialogClassName="setupModal"
      animation={false}
      size="xl"
    >
      <ScoreSheetConfig {...props} />
    </Modal>
  );
}

function Clock({ timezone }) {
  const clock = useClockTime();
  const tz = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  return `${formatInTimeZone(
    clock,
    tz,
    "EEE M'/'dd'/'yyyy @ hh:mm:ss aaa zzz"
  )}`;
}

function ScoreSheetLineup({
  squad,
  showSidebar = true,
  apparatus,
  max,
  isIndividuals,
  teamsByAthleteId,
}) {
  const {
    apparatusName,
    apparatusNameSpan,
    orderNum,
    score,
    name,
    nameTd,
    finalScore,
    scoreSB,
    scoreND,
  } = officialscoresheetStyles;
  const data = (!!squad && squad.slice(0, max)) || [];
  const rows = [...data, ...new Array(max - data.length).fill({})];
  const evalConfig = useEvalConfig();

  const {
    dPanel,
    ePanel,
    jPanel,
    nDeduct,
    startValue,
    stickBonus,
  } = evalConfig;

  const scoreRow = (r, index) => {
    const teamName = displayName(
      teamsByAthleteId?.[r.athleteId]?.name,
      teamsByAthleteId?.[r.athleteId]?.altNames
    );
    const teamNameStr = isIndividuals ? ` (${teamName})` : '';
    const athleteName = r?.name ? `${r?.name}${teamNameStr}` : '';

    return (
      <tr key={`${r.name}_${index}`}>
        <td className={orderNum}>{index + 1}</td>
        <td className={nameTd}>
          <div className={name}>
            <span>{athleteName}</span>
          </div>
        </td>
        {!startValue
          ? dPanel?.map((d) => (
              <td key={d.type} className={score}>
                {r?.scores?.[d.type]}
              </td>
            ))
          : null}
        {!!ePanel && ePanel.length > 0 ? (
          <td key="Eavg" className={score}>
            {r?.scores?.scoreEAverage}
          </td>
        ) : null}
        {ePanel?.map((e) => (
          <td key={e.type} className={score}>
            {r?.scores?.[e.type]}
          </td>
        ))}
        {jPanel?.map((j) => (
          <td key={j.type} className={score}>
            {r?.scores?.[j.type]}
          </td>
        ))}
        {nDeduct ? <td className={scoreND}>{r?.scores?.ND}</td> : null}
        {stickBonus ? <td className={scoreSB}>{r?.scores?.SB}</td> : null}
        <td className={finalScore}>{r?.finalScore}</td>
      </tr>
    );
  };

  return (
    <Table>
      <tbody>
        <tr>
          {showSidebar ? (
            <th className={apparatusName} rowSpan={rows.length + 1}>
              <span className={apparatusNameSpan}>{apparatus}</span>
            </th>
          ) : null}
          <th className={orderNum}>#</th>
          <th>Name</th>
          {!startValue ? (
            dPanel.length === 1 ? (
              <th key="Diff." className={score}>
                Diff.
              </th>
            ) : (
              dPanel?.map((d) => (
                <th key={d.type} className={score}>
                  {d.type}
                </th>
              ))
            )
          ) : null}
          {!!ePanel && ePanel.length > 0 ? (
            <th key="Exec." className={score}>
              Exec.
            </th>
          ) : null}
          {ePanel?.map((e) => (
            <th key={e.type} className={score}>
              {e.type}
            </th>
          ))}
          {jPanel?.map((j) => (
            <th key={j.type} className={score}>
              {j.type}
            </th>
          ))}
          {nDeduct ? <th className={scoreND}>ND</th> : null}
          {stickBonus ? <th className={scoreSB}>SB</th> : null}
          <th className={finalScore}>Score</th>
        </tr>
        {rows.map((r, i) => scoreRow(r, i))}
      </tbody>
    </Table>
  );
}

function ScoreSheetJudgeLineup({
  judges,
  showSidebar = true,
  apparatus,
  max,
  gender,
}) {
  const {
    apparatusName,
    apparatusNameSpan,
    orderNum,
    name,
    nameTdJudge,
    judgeRating,
  } = officialscoresheetStyles;

  const evalConfig = useEvalConfig();
  const { dPanel, ePanel, jPanel } = evalConfig;

  const rankIt = (scoreTypes) => {
    const judgeRanking = [...dPanel, ...ePanel, ...jPanel];
    let rank = judgeRanking.length;

    for (let i = 0; i < judgeRanking.length; i++) {
      if (scoreTypes.includes(judgeRanking[i].type)) {
        rank = i;
        break;
      }
    }

    return rank;
  };

  const dataFlat = [judges?.headJudge, ...Object.values(judges?.rest)];
  const data = dataFlat.sort((a, b) => {
    if (rankIt(a.scoreTypes) < rankIt(b.scoreTypes)) {
      return -1;
    }
    if (rankIt(a.scoreTypes) > rankIt(b.scoreTypes)) {
      return 1;
    }
    return 0;
  });

  const rows = [...data, ...new Array(max - data.length).fill({})];

  const scoreRow = (r, index) => {
    return (
      <tr key={`${index}`}>
        <td className={orderNum}>{index + 1}</td>
        <td className={nameTdJudge}>
          <div className={name}>
            <span>{r?.sessionJudgeAssignment?.judge?.name}</span>
          </div>
        </td>
        <td className={judgeRating}>
          {getJudgeDisplayContext(r, gender, false)}
        </td>
      </tr>
    );
  };

  return (
    <Table>
      <tbody>
        <tr>
          {showSidebar ? (
            <th className={apparatusName} rowSpan={rows.length + 1}>
              <span className={apparatusNameSpan}>{apparatus}</span>
            </th>
          ) : null}
          <th className={orderNum}>#</th>
          <th>Name</th>
          <th className={judgeRating}>Rating</th>
        </tr>
        {rows.map((r, i) => scoreRow(r, i))}
      </tbody>
    </Table>
  );
}

function ScoreSheetAA({ data = [], showSidebar = true, apparatus, max }) {
  const {
    apparatusName,
    orderNum,
    aaScore,
    aaScoreTd,
    apparatusNameSpan,
  } = officialscoresheetStyles;

  const rows = ['name', ...apparatus, 'AA'];
  const columns = [
    ...data.slice(0, max),
    ...new Array(max - data.length).fill({}),
  ];

  const aaRow = (r, data, index) => {
    return (
      <tr key={`${r}_${index}`}>
        {index === 0 ? (
          <th className={orderNum}>#</th>
        ) : (
          <td className={orderNum}>{r}</td>
        )}
        {columns.map((athlete, i) => {
          if (index === 0) {
            return (
              <th key={i} className={aaScoreTd}>
                <div className={aaScore}>
                  <span>{fullNameToInitialLastName(athlete?.name)}</span>
                </div>
              </th>
            );
          }
          return (
            <td key={i} className={aaScoreTd}>
              <span>{athlete?.scores?.[r]?.score}</span>
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <Table>
      <tbody>
        <tr>
          {showSidebar ? (
            <th className={apparatusName} rowSpan={rows.length + 1}>
              <span className={apparatusNameSpan}>AA</span>
            </th>
          ) : null}
        </tr>
        {rows.map((a, i) => aaRow(a, data, i))}
      </tbody>
    </Table>
  );
}

function ScoreSheetRounds({ data, apparatus }) {
  const {
    rotationScore,
    rotationScoreLabel,
    rotationScoreValue,
  } = officialscoresheetStyles;

  return (
    <Row className={rotationScore}>
      <Col className={rotationScoreLabel}>{`${apparatus} Score:`}&nbsp;</Col>
      <Col xs={2} className={rotationScoreValue}>
        {data?.eventScore}
      </Col>
      <Col style={{ padding: '0' }} />
      <Col className={rotationScoreLabel}>Running Score:&nbsp;</Col>
      <Col xs={2} className={rotationScoreValue}>
        {data?.runningScore}
      </Col>
    </Row>
  );
}

const displayedScoreTypes = [
  ScoreType.D,
  ScoreType.D1,
  ScoreType.D2,
  ScoreType.E,
  ScoreType.E1,
  ScoreType.E2,
  ScoreType.E3,
  ScoreType.E4,
  ScoreType.E5,
  ScoreType.E6,
];

function getJudgeDisplayContext(stageJudge, gender, parentheses = true) {
  let result = '';

  if (!stageJudge || Object.keys(stageJudge).length === 0) {
    return result;
  }

  if (gender === GenderType.FEMALE) {
    if (stageJudge.sessionJudgeAssignment.judge.womensBrevet) {
      result = 'Brevet';
    } else if (stageJudge.sessionJudgeAssignment.judge.womensNational) {
      result = 'National';
    } else if (stageJudge.sessionJudgeAssignment.judge.womensLevel10) {
      result = 'Level 10';
    }
  } else {
    result =
      (stageJudge?.scoreTypes &&
        `${stageJudge.scoreTypes
          .filter((scoreType) => displayedScoreTypes.indexOf(scoreType) !== -1)
          .join(', ')}`) ||
      '';
  }

  if (result !== '' && parentheses) {
    result = `(${result})`;
  }

  return result;
}

function TeamSummaryScoreSheetTable({ teams, apparatus }) {
  const {
    scoreSheetListTable,
    scoreSheetListRank,
    scoreSheetListScore,
    scoreSheetListTotal,
    scoreSheetListTie,
    scoreSheetListTeam,
    scoreSheetListOut,
    reduceFont,
  } = officialscoresheetStyles;

  if (!teams) return null;

  const teamsSorted = teams
    .slice()
    .filter((t) => !t.individuals)
    .sort((a, b) => {
      const rankA = a.rank;
      const rankB = b.rank;
      if (rankA === '' && rankB !== '') {
        return 1;
      } else if (rankA !== '' && rankB === '') {
        return -1;
      } else {
        return rankA - rankB;
      }
    });

  const isReduceFont = apparatus.length > 4 ? reduceFont : null;

  const teamRow = (r, index) => {
    return (
      <tr key={`${index}`}>
        <td className={scoreSheetListRank}>{r.rank}</td>
        <td className={scoreSheetListTie}>{r.isTie ? 'T' : null}</td>
        <td className={scoreSheetListTeam}>{r.displayName}</td>
        {apparatus.map((a, i) => {
          return (
            <td key={`${index}_${a}`} className={scoreSheetListScore}>
              {r?.events?.find((e) => e.apparatus === a)?.eventScore}
            </td>
          );
        })}
        <td className={scoreSheetListTotal}>{r.finalScore}</td>
        <td className={scoreSheetListOut}>{r.rankOut}</td>
      </tr>
    );
  };

  return (
    <Table className={[scoreSheetListTable, isReduceFont].join(' ')}>
      <thead>
        <tr>
          <th className={scoreSheetListRank}>Rank</th>
          <th className={scoreSheetListTie} />
          <th className={scoreSheetListTeam}>Team</th>
          {apparatus.map((a) => {
            return (
              <th key={`header_${a}`} className={scoreSheetListScore}>
                {a}
              </th>
            );
          })}
          <th className={scoreSheetListTotal}>Total</th>
          <th className={scoreSheetListOut}>Out</th>
        </tr>
      </thead>
      <tbody>{teamsSorted.map((t, i) => teamRow(t, i))}</tbody>
    </Table>
  );
}

function AAScoreSheetTable({ individuals, apparatus }) {
  const {
    scoreSheetListTable,
    scoreSheetListRank,
    scoreSheetListScore,
    scoreSheetListTotal,
    scoreSheetListTeam,
    scoreSheetListTie,
    scoreSheetListOut,
    reduceFont,
  } = officialscoresheetStyles;

  if (!individuals) return null;

  const individualsSorted = individuals.slice().sort((a, b) => {
    const rankA = a['scores']['AA']['rank'];
    const rankB = b['scores']['AA']['rank'];
    if (rankA === '' && rankB !== '') {
      return 1;
    } else if (rankA !== '' && rankB === '') {
      return -1;
    } else {
      return rankA - rankB;
    }
  });

  const isReduceFont = apparatus.length > 4 ? reduceFont : null;

  const individualRow = (r, index) => {
    const scores = r?.['scores'];
    const scoreAA = scores?.['AA'];

    return (
      <tr key={`${index}`}>
        <td className={scoreSheetListRank}>{scoreAA?.rank}</td>
        <td className={scoreSheetListTie}>{scoreAA?.isTie ? 'T' : null}</td>
        <td className={scoreSheetListTeam}>
          <span>{r?.name}</span>
        </td>
        <td className={scoreSheetListTeam}>{r?.team}</td>
        {apparatus.map((a) => {
          return (
            <td key={`${index}_${a}`} className={scoreSheetListScore}>
              {scores[a].score}
            </td>
          );
        })}
        <td className={scoreSheetListTotal}>{scoreAA.score}</td>
        <td className={scoreSheetListOut}>{scoreAA.rankOut}</td>
      </tr>
    );
  };

  return (
    <Table className={[scoreSheetListTable, isReduceFont].join(' ')}>
      <thead>
        <tr>
          <th className={scoreSheetListRank}>Rank</th>
          <th className={scoreSheetListTie} />
          <th className={scoreSheetListTeam}>Name</th>
          <th className={scoreSheetListTeam}>Team</th>
          {apparatus.map((a) => {
            return (
              <th key={`header_${a}`} className={scoreSheetListScore}>
                {a}
              </th>
            );
          })}
          <th className={scoreSheetListTotal}>Total</th>
          <th className={scoreSheetListOut}>Out</th>
        </tr>
      </thead>
      <tbody>
        {individualsSorted.map((athlete, i) => individualRow(athlete, i))}
      </tbody>
    </Table>
  );
}

function ApparatusScoreSheetTable({
  individuals,
  apparatus,
  teamsByAthleteId,
}) {
  const {
    scoreSheetListTable,
    scoreSheetListRank,
    scoreSheetListTotal,
    scoreSheetListTie,
    scoreSheetListTeam,
    scoreSheetListOut,
    reduceFont,
  } = officialscoresheetStyles;

  if (!individuals) return null;

  const individualsSorted = individuals.slice().sort((a, b) => {
    const rankA = a['rank'];
    const rankB = b['rank'];
    if (rankA === '' && rankB !== '') {
      return 1;
    } else if (rankA !== '' && rankB === '') {
      return -1;
    } else {
      return rankA - rankB;
    }
  });

  const isReduceFont = apparatus.length > 4 ? reduceFont : null;

  const individualRow = (r, index) => {
    return (
      <tr key={`${index}`}>
        <td className={scoreSheetListRank}>{r?.rank}</td>
        <td className={scoreSheetListTie}>{r?.isTie ? 'T' : null}</td>
        <td className={scoreSheetListTeam}>{r?.name}</td>
        {/*<td className={scoreSheetListTeam}>{r?.team}</td>*/}
        <td className={scoreSheetListTeam}>
          {displayName(
            teamsByAthleteId?.[r.athleteId]?.name,
            teamsByAthleteId?.[r.athleteId]?.altNames
          )}
        </td>
        <td className={scoreSheetListTotal}>{r?.score}</td>
        <td className={scoreSheetListOut}>{r?.rankOut}</td>
      </tr>
    );
  };

  return (
    <Table className={[scoreSheetListTable, isReduceFont].join(' ')}>
      <thead>
        <tr>
          <th className={scoreSheetListRank}>Rank</th>
          <th className={scoreSheetListTie} />
          <th className={scoreSheetListTeam}>Name</th>
          <th className={scoreSheetListTeam}>Team</th>
          <th className={scoreSheetListTotal}>Score</th>
          <th className={scoreSheetListOut}>Out</th>
        </tr>
      </thead>
      <tbody>
        {individualsSorted.map((athlete, i) => individualRow(athlete, i))}
      </tbody>
    </Table>
  );
}

function TeamBreakdownScoreSheetTable({ team, apparatus, teamsByAthleteId }) {
  const {
    scoreSheetListTable,
    scoreSheetListScore,
    scoreSheetListTotalEnd,
    scoreSheetListTeam,
    reduceFont,
  } = officialscoresheetStyles;

  if (!team) return null;

  const athletes = {};

  team.events.forEach((e) => {
    e.lineup.forEach((l) => {
      athletes[l.name] = {
        ...athletes[l.name],
        athleteId: l.athleteId,
        ...{
          [e.apparatus]: {
            isTeamRoutine: l.isTeamRoutine,
            score: l.finalScore,
            exhibition: l.exhibition,
          },
        },
      };
    });
  });

  const athleteList = [];
  Object.keys(athletes).forEach((a) => {
    const name = a.trim();
    const lastName = name.split(' ')[name.split(' ').length - 1];
    athleteList.push({
      ...athletes[a],
      name,
      lastName,
    });
  });

  const sortedAthletes = athleteList.slice().sort((a, b) => {
    const nameA = a.lastName.toLowerCase();
    const nameB = b.lastName.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameB > nameA) return 1;
    return 0;
  });

  const isReduceFont = apparatus.length > 4 ? reduceFont : null;

  const athleteRow = (r, index) => {
    const teamName = displayName(
      teamsByAthleteId?.[r.athleteId]?.name,
      teamsByAthleteId?.[r.athleteId]?.altNames
    );
    const teamNameStr = team?.individuals ? ` (${teamName})` : '';
    const athleteName = r?.name ? `${r?.name}${teamNameStr}` : '';

    return (
      <tr key={`${index}`}>
        <td className={scoreSheetListTeam}>{athleteName.substring(0, 30)}</td>
        {apparatus.map((e) => {
          const isBold = r?.[e]?.isTeamRoutine && !team.individuals;
          const score = r?.[e]?.score;
          return (
            <td key={`${index}_${e}`} className={scoreSheetListScore}>
              <sub>{r?.[e]?.exhibition ? 'EX ' : ''}</sub>
              <span style={{ fontWeight: isBold ? 'bold' : null }}>
                {score}
              </span>
            </td>
          );
        })}
        <td className={scoreSheetListTotalEnd}>
          {team.aa.find((a) => a.name === r.name)?.scores?.AA?.score}
        </td>
      </tr>
    );
  };

  return (
    <Table className={[scoreSheetListTable, isReduceFont].join(' ')}>
      <thead>
        <tr>
          <th className={scoreSheetListTeam} style={{ width: '40pt' }}>
            {team.displayName}
          </th>
          {apparatus.map((a) => {
            return (
              <th key={`header_${a}`} className={scoreSheetListScore}>
                {a}
              </th>
            );
          })}
          <th className={scoreSheetListTotalEnd}>AA</th>
        </tr>
      </thead>
      <tbody>{sortedAthletes.map((a, i) => athleteRow(a, i))}</tbody>
      <tfoot>
        <tr>
          {!team.individuals && (
            <>
              <td className={scoreSheetListTeam}>
                Rank #{team.rank}
                {team.isTie ? 'T' : null}
              </td>
              {apparatus.map((a) => {
                return (
                  <td key={`footer_${a}`} className={scoreSheetListScore}>
                    {team.events.find((e) => e.apparatus === a)?.eventScore}
                  </td>
                );
              })}

              <td className={scoreSheetListTotalEnd}>{team.finalScore}</td>
            </>
          )}
        </tr>
      </tfoot>
    </Table>
  );
}

function LineupVerificationFormTable({ team, apparatus }) {
  const {
    formLineupVerificationTable,
    formLineupVerificationLineup,
    formLineupVerificationUpdate,
    reduceFont,
  } = officialscoresheetStyles;

  const isReduceFont = apparatus.length > 4 ? reduceFont : null;
  const apparatusList = apparatus;

  const placeHolderLineup = [...Array(6).keys()].map((el) => {
    return {
      name: '',
    };
  });

  const lineups = team.events.reduce((acc, e) => {
    if (apparatus.includes(e.apparatus)) {
      acc[e.apparatus] = e.lineup;
    }
    return acc;
  }, {});

  return (
    <Table className={[formLineupVerificationTable, isReduceFont].join(' ')}>
      <thead>
        <tr>
          {apparatusList.map((e) => {
            return (
              <th
                key={`${team.id}_${e}_header`}
                className={formLineupVerificationLineup}
              >
                {e}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          {apparatusList.map((e) => {
            const lineup = lineups?.[e] || placeHolderLineup;

            return (
              <td
                key={`${team.id}_${e}_body`}
                className={formLineupVerificationLineup}
              >
                <ol>
                  {lineup.map((l, i) => {
                    return <li key={`${l.name}_${i}`}>{l.name}</li>;
                  })}
                </ol>
              </td>
            );
          })}
        </tr>
        <tr>
          {apparatusList.map((e) => {
            return (
              <td
                key={`${team.id}_${e}_update`}
                className={formLineupVerificationUpdate}
              >
                <ol>
                  {[...Array(8).keys()].map((l) => {
                    return <li key={l}>________________________</li>;
                  })}
                </ol>
              </td>
            );
          })}
        </tr>
      </tbody>
    </Table>
  );
}

function useClockTime() {
  const [clock, setClock] = useState(Date.now());
  useInterval(
    async () => {
      setClock(Date.now());
    },
    POLLING_INTERVAL.SECOND,
    true
  );

  return clock;
}

function generateSheetOptions(teams, individuals, format, apparatus = []) {
  const isChampionships = !!format?.isChampionships;
  const isMakeMultiDuals = !!format?.isMakeMultiDuals;
  const isLineupVerification = !!format?.isLineupVerification;
  const numTeams = teams.length || 0;
  const numAA = individuals?.AA?.length || 0;

  const summary = numTeams > 0 ? ['SUMMARY'] : [];
  const AA = numAA > 0 ? ['AA'] : [];
  const championships = isChampionships
    ? teams.map((t) => `CHAMPIONSHIPS${t.order}`)
    : [];

  const teamPairs = [];
  const breakdown = [];
  const lineups = [];

  for (let i = 0; i < Math.round(numTeams / 2); i++) {
    breakdown.push(`BREAKDOWN${i * 2}_${i * 2 + 1}`);
    if (!isMakeMultiDuals) {
      teamPairs.push(`TEAM${i * 2}_${i * 2 + 1}`);
    }
  }

  for (let i = 0; i < Math.round(numTeams); i++) {
    lineups.push(`LINEUP${i}`);
  }

  if (isMakeMultiDuals) {
    for (let i = 0; i < numTeams; i++) {
      for (let j = i + 1; j < numTeams; j++) {
        const pair = `TEAM${i}_${j}`;
        teamPairs.push(pair);
      }
    }
  }

  if (isLineupVerification) {
    return [...lineups];
  }

  return [
    ...(isChampionships ? championships : teamPairs),
    ...summary,
    ...breakdown,
    ...apparatus,
    ...AA,
  ];
}

const SCORESHEET_TYPES = {
  TEAM: 'TEAM',
  SUMMARY: 'SUMMARY',
  CHAMPIONSHIPS: 'CHAMPIONSHIPS',
  APPARATUS: 'APPARATUS',
  AA: 'AA',
  BREAKDOWN: 'BREAKDOWN',
  LINEUP: 'LINEUP',
};

export default function OfficialScoreSheet(props) {
  const [requestedCopy, setRequestedCopy] = useState(false);
  const { sessionId } = props;
  // const judgingEnabled = useJudgingEnabled();

  // Get the full query params to preserve them on redirect
  const { queryParams } = useQueryParamsAdv(
    {
      s: sessionId,
      officialscoresheet: 0,
      combinekey: '',
      tz: '',
      nojudge: '',
      print: '',
      lineupverification: '',
      singlesheet: '',
    },
    true
  );

  // TODO: Re-enable this when we have a proper way to handle unauthorized users
  // Redirect unauthorized users back to regular session view with all params
  // if (!judgingEnabled) {
  //   const params = new URLSearchParams(queryParams);
  //   params.delete('officialscoresheet'); // Remove scoresheet param
  //   return <Redirect to={`/session?${params.toString()}`} />;
  // }

  // const { role } = useSelector((state) => state.session);

  const { data, networkStatus, loading } = useQuery(GetFullSession, {
    variables: { id: sessionId },
  });

  useEffect(() => {
    if (data && requestedCopy && networkStatus !== NetworkStatus.refetch) {
      setRequestedCopy(false);
    }
  }, [requestedCopy, networkStatus, data]);

  const session = data?.getSession || {};
  const format = scoresheetFormat(session?.judgePanel);
  const newProps = { ...props, session, loading };

  return loading ? (
    <FullscreenLoading message="Loading Scoresheet..." />
  ) : (
    <ScoreSheetConfigContext
      session={session}
      format={format}
      noJudge={queryParams?.nojudge}
      singleSheet={queryParams?.singlesheet}
      lineupVerificationMode={queryParams?.lineupverification}
    >
      <OfficialScoreSheetInner {...newProps} />
      <SessionSubscriptionManager sessionId={sessionId} />
    </ScoreSheetConfigContext>
  );
}

function OfficialScoreSheetInner({ sessionId, type, session }) {
  const evalConfig = useEvalConfig();
  const dispatch = useDispatch();
  const { queryParams, setQueryParams } = useQueryParamsAdv(
    {
      s: '',
      officialscoresheet: 0,
      combinekey: '',
      combinekeys: '',
      tz: '',
      nojudge: '',
      singlesheet: '',
      lineupverification: '',
      print: '',
    },
    true
  ); // using replace instead of push
  const lineupMax = evalConfig?.maxTeamLineup || 6;
  const [teamAIndex, setTeamAIndex] = useState(null);
  const [teamBIndex, setTeamBIndex] = useState(null);
  const [location, setLocation] = useState('');
  const [altTeamScoring, setAltTeamScoring] = useState(null);
  const [attendance, setAttendance] = useState('');
  const [combineSessions, setCombineSessions] = useState([]);
  const [combineKey, setCombineKey] = useState(queryParams?.combinekey || null);
  const [combineKeys, setCombineKeys] = useState(
    queryParams?.combinekeys ? queryParams.combinekeys.split(',') : []
  );
  // const [combineURL, setCombineURL] = useState('');
  const [showSetup, setShowSetup] = useState(false);
  //const [selector, setSelector] = useState('TEAM');
  const [toggleNextSheet, setToggleNextSheet] = useState(true);
  const debouncedToggle = useCallback(debounce(setToggleNextSheet, 1000), []);
  const {
    multiDualSheets,
    lineupVerification,
    hideJudgeNames,
    singleSheetOnly,
  } = useContext(ScoreSheetContext);
  // For multiple combine sessions
  const combinedSessions =
    useCombinedSessions({ sessionKeys: combineKeys }) || [];
  const [combinedSessionsLoaded, setCombinedSessionsLoaded] = useState({});

  // For backward compatibility
  const combineSession = useSessionByKey({ sessionKey: combineKey });
  const combineLoaded = !!combineKey && combineSession?.session;

  // Debug logs
  // useEffect(() => {
  //   console.log('combineKeys updated:', combineKeys);
  //   console.log('combinedSessions:', combinedSessions);
  //   console.log('combinedSessionsLoaded:', combinedSessionsLoaded);
  // }, [combineKeys, combinedSessions, combinedSessionsLoaded]);

  // Reset combined sessions loaded state when we're down to 0 or 1 sessions
  useEffect(() => {
    if (combineKeys.length <= 0 && !combineKey) {
      // If no sessions at all, clear everything
      setCombinedSessionsLoaded({});
    }
  }, [combineKeys, combineKey]);

  const timezone =
    queryParams?.tz || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const print = queryParams?.print === '1' ? true : false;
  const noJudge = queryParams?.nojudge === '1' ? true : false;
  const singleSheet = queryParams?.singlesheet === '1' ? true : false;
  const lineupVerificationMode =
    queryParams?.lineupverification === '1' ? true : false;

  const [sheetIndex, setSheetIndex] = useState(
    parseInt(queryParams?.officialscoresheet) - 1 || 0
  );

  useEffect(() => {
    if (combineLoaded) {
      setCombineSessions(['https://virti.us/session?s=' + combineKey]);

      if (!print) {
        dispatch(
          alertActions.success(
            `Combine session ${combineSession.session.name} successfully loaded.`
          )
        );
      }
    }
  }, [combineLoaded, print]);

  useEffect(() => {
    if (
      combinedSessions &&
      combinedSessions.length > 0 &&
      combineKeys.length > 0
    ) {
      const newCombinedSessionsLoaded = {};
      let newSessionsLoaded = false;

      combinedSessions.forEach((session, index) => {
        if (session && !combinedSessionsLoaded[combineKeys[index]]) {
          newCombinedSessionsLoaded[combineKeys[index]] = true;
          newSessionsLoaded = true;

          if (!print) {
            dispatch(
              alertActions.success(
                `Combine session ${session.name} successfully loaded.`
              )
            );
          }
        }
      });

      if (newSessionsLoaded) {
        setCombinedSessionsLoaded({
          ...combinedSessionsLoaded,
          ...newCombinedSessionsLoaded,
        });
      }
    }
  }, [combinedSessions, print, combineKeys, dispatch]);

  useHotkeys(
    's',
    (e) => {
      e.preventDefault();
      setShowSetup(true);
    },
    [showSetup]
  );

  useHotkeys(
    'up',
    (e) => {
      e.preventDefault();
      handleUp(e);
    },
    [sheetIndex]
  );

  useHotkeys(
    'down',
    (e) => {
      e.preventDefault();
      handleDown(e);
    },
    [sheetIndex]
  );

  const handleUp = (e) => {
    e.preventDefault();
    const newSheetIndex = sheetIndex > 0 ? sheetIndex - 1 : 0;
    setToggleNextSheet(true);
    setSheetIndex(newSheetIndex);
  };

  const handleDown = (e) => {
    e.preventDefault();
    const newSheetIndex =
      sheetIndex < sheetOptions.length - 1 ? sheetIndex + 1 : sheetIndex;
    setToggleNextSheet(true);
    setSheetIndex(newSheetIndex);
  };

  // To add the combinekey to the query URL (for backward compatibility)
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      combinekey: combineKey || '',
    });
  }, [combineKey]);

  // To add the combinekeys to the query URL
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      combinekeys: combineKeys.length > 0 ? combineKeys.join(',') : undefined,
    });
  }, [combineKeys]);

  // To add the lineupverification to the query URL
  useEffect(() => {
    const newQueryParams = {
      ...queryParams,
      lineupverification: lineupVerification ? 1 : undefined,
      nojudge: hideJudgeNames ? 1 : undefined,
      singlesheet: singleSheetOnly ? 1 : undefined,
    };

    if (!lineupVerification) {
      delete newQueryParams.lineupverification;
    }

    if (!hideJudgeNames) {
      delete newQueryParams.nojudge;
    }

    if (!singleSheetOnly) {
      delete newQueryParams.singlesheet;
    }

    setQueryParams(newQueryParams);
  }, [lineupVerification, hideJudgeNames, singleSheetOnly]);

  // To automatically scroll to new sheetIndex, including on page load
  useEffect(() => {
    const isSafeIndex = sheetIndex > 0 && sheetIndex < sheetOptions.length;
    let safeIndex = 0;

    if (isSafeIndex) {
      safeIndex = sheetIndex;
    } else {
      setSheetIndex(0);
    }

    // console.log(safeIndex);
    // console.log(sheetIndex);
    // console.log(toggleNextSheet);

    setQueryParams({
      ...queryParams,
      officialscoresheet: parseInt(safeIndex) + 1,
    });

    if (toggleNextSheet) {
      const element = document.getElementById(`scoresheet_${safeIndex + 1}`);
      if (!element) {
        return;
      }
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      debouncedToggle(false);
    }
  }, [sheetIndex, toggleNextSheet]);

  const gender = session?.gender;
  const sessionKey = session?.sessionKey;
  const sessionName = session?.name;
  // console.log(combinedSessions)

  const scoresheet = useCombineScoreSheet();
  const scoresheetData = scoresheet(session, {
    altTeamScoring,
    combineSession: combineSession?.session,
    combinedSessions: combinedSessions.filter((s) => s),
  });

  // console.log({ scoresheetData, combineScoresheetData });

  const { date, format, sessionData } = scoresheetData;
  const { teams, individuals } = sessionData;
  const apparatusArray = Object.keys(GYMapparatus(gender));

  // Get teams by athlete ID for all combined sessions
  // const combineTeamsByAthleteId =
  //   useTeamsByAthleteId(combineSession?.session?.id) || {};

  // Create a merged object of all teams by athlete ID from all combined sessions
  // const combinedTeamsByAthleteId = combinedSessions.reduce((acc, session) => {
  //   const sessionTeams = useTeamsByAthleteId(session?.id) || {};
  //   return { ...acc, ...sessionTeams };
  // }, {});

  // Collect all teams by athlete ID before reducing
  // const teamsBySession = combinedSessions.map(session => ({
  //   sessionId: session?.id,
  //   teams: useTeamsByAthleteId(session?.id) || {}
  // }));

  // // Merge teams into a single object
  // const combinedTeamsByAthleteId = teamsBySession.reduce((acc, { teams }) => {
  //   return { ...acc, ...teams };
  // }, {});

  // Get teams by athlete ID for the main session
  const mainTeamsByAthleteId = useTeamsByAthleteId(sessionId);

  // Get teams by athlete ID for the legacy combine session
  const combineTeamsByAthleteId = useTeamsByAthleteId(
    combineSession?.session?.id
  );

  // Get combined session IDs
  const combinedSessionIds = useMemo(
    () => combinedSessions.map((session) => session?.id).filter(Boolean),
    [combinedSessions]
  );

  // Use the new hook to get teams for all combined sessions
  const combinedTeamsByAthleteId = useCombinedTeamsByAthleteIds(
    combinedSessionIds
  );

  // Merge all team data
  const teamsByAthleteId = useMemo(
    () => ({
      ...mainTeamsByAthleteId,
      ...combineTeamsByAthleteId,
      ...combinedTeamsByAthleteId,
    }),
    [mainTeamsByAthleteId, combineTeamsByAthleteId, combinedTeamsByAthleteId]
  );

  const sheetOptions =
    generateSheetOptions(
      teams,
      individuals,
      {
        ...format,
        isMakeMultiDuals: multiDualSheets,
        isLineupVerification: lineupVerification,
      },
      apparatusArray
    ) || [];

  useEffect(() => {
    if (scoresheetData.location) {
      setLocation(scoresheetData.location);
    }
  }, [scoresheetData.location]);

  useEffect(() => {
    if (scoresheetData.attendance) {
      setAttendance(scoresheetData.attendance);
    }
  }, [scoresheetData.attendance]);

  const {
    header,
    main,
    first,
    mainF,
    mainM,
    row,
    teamScoreSheet,
    reduceFont,
    lineupForm,
    teamName,
    teamNameLabel,
    teamNameTeam,
    matchDetailsShort,
    matchDetailsLong,
    matchDetailsLabel,
    matchDetailsShortField,
    matchDetailsLongField,
    matchDetailsEditButton,
    body,
    squadLeft,
    squadRight,
    squadFull,
    footer,
    rotationScore,
    subFooter,
    signature,
    signatureLabel,
    signatureField,
    signatureName,
    signatureNames,
    yellowCard,
    timestamp,
    teamScore,
    teamScoreLabel,
    teamScoreValue,
    teamScoreValueEmpty,
    headJudge,
    virtiusTitle,
    sessionInfo,
    sessionInfoLabel,
    sessionInfoValue,
    noPrint,
  } = officialscoresheetStyles;

  const genderClass = session.gender === GenderType.FEMALE ? mainF : mainM;

  const judgesByApparatus =
    session.gender === GenderType.FEMALE
      ? {
          [GymApparatus.VT]: { headJudge: null, rest: {} },
          [GymApparatus.UB]: { headJudge: null, rest: {} },
          [GymApparatus.BB]: { headJudge: null, rest: {} },
          [GymApparatus.FX]: { headJudge: null, rest: {} },
        }
      : {
          [GymApparatus.FX]: { headJudge: null, rest: {} },
          [GymApparatus.PH]: { headJudge: null, rest: {} },
          [GymApparatus.SR]: { headJudge: null, rest: {} },
          [GymApparatus.VT]: { headJudge: null, rest: {} },
          [GymApparatus.PB]: { headJudge: null, rest: {} },
          [GymApparatus.HB]: { headJudge: null, rest: {} },
        };

  const headJudgeScoreType =
    session.gender === GenderType.FEMALE ? ScoreType.J1 : ScoreType.D;

  // Judge configuration
  session?.rotations?.items.forEach((rotation) => {
    if (!rotation._deleted) {
      rotation.stages.items.forEach((stage) => {
        if (!stage._deleted) {
          const judgesForStage = judgesByApparatus[stage.apparatus];
          stage.judges.items.forEach((stageJudge, index) => {
            if (!stageJudge._deleted) {
              const thisJudgeId = stageJudge.sessionJudgeAssignment.judge.id;
              if (stageJudge.scoreTypes.indexOf(headJudgeScoreType) !== -1) {
                if (!judgesForStage.headJudge) {
                  judgesForStage.headJudge = stageJudge;
                } else if (
                  judgesForStage.headJudge.sessionJudgeAssignment.judge.id !==
                    thisJudgeId &&
                  !judgesForStage.rest[thisJudgeId]
                ) {
                  judgesForStage.rest[thisJudgeId] = stageJudge;
                }
              } else if (!judgesForStage.rest[thisJudgeId]) {
                judgesForStage.rest[thisJudgeId] = stageJudge;
              }
            }
          });
        }
      });
    }
  });

  const maxJudges = Object.keys(judgesByApparatus).reduce(
    (acc, apparatus) =>
      Math.max(acc, Object.keys(judgesByApparatus[apparatus].rest).length),
    0
  );

  const copyrightTimePageFooter = (page) => {
    return (
      <section className={subFooter}>
        <Row>
          <Col className={timestamp}>
            <span>
              © {new Date().getFullYear()} Virtius&nbsp;&nbsp;»&nbsp;&nbsp;
            </span>
            <Clock timezone={timezone} />
            <span>
              &nbsp;&nbsp;»&nbsp;&nbsp;Page {singleSheetOnly ? 1 : page} of{' '}
              {singleSheetOnly ? 1 : sheetOptions.length}
            </span>
          </Col>
        </Row>
      </section>
    );
  };

  const teamScoresheetHeader = (teamA, teamB) => {
    return (
      <section className={header}>
        <h1>{format.title}</h1>
        <Row className={row}>
          <Col className={teamName}>
            <div className={teamNameLabel}>Home: </div>
            <div className={teamNameTeam}>
              {(teamA && teamA?.forceTitle
                ? `${teamA?.altName}: ${teamA?.displayName}`
                : teamA?.altName) || ''}
              {teamA?.individuals ? ' (Individuals)' : ''}
            </div>
          </Col>
          <Col className={teamName}>
            <div className={teamNameLabel}>Visitor: </div>
            <div className={teamNameTeam}>
              {(teamB && teamB?.forceTitle
                ? `${teamB?.altName}: ${teamB?.displayName}`
                : teamB?.altName) || ''}
              {teamB?.individuals ? ' (Individuals)' : ''}
            </div>
          </Col>
        </Row>
        <Row className={row}>
          <Col className={matchDetailsShort}>
            <div className={matchDetailsLabel}>Date: </div>
            <div className={matchDetailsShortField}>{`${formatInTimeZone(
              new Date(date),
              timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
              "M'/'dd'/'yyyy"
            )}`}</div>
          </Col>
          <Col className={matchDetailsLong}>
            <div className={matchDetailsLabel}>Location: </div>
            <div className={matchDetailsLongField}>{location}</div>
            {location ? null : (
              <Button
                variant="light"
                className={matchDetailsEditButton}
                onClick={() => setShowSetup(true)}
              >
                {editIcon}
              </Button>
            )}
          </Col>
          <Col className={matchDetailsShort}>
            <div className={matchDetailsLabel}>Attendance: </div>
            <div className={matchDetailsShortField}>
              {attendance.length === 0
                ? ''
                : Number(attendance).toLocaleString()}
            </div>
            {attendance.length !== 0 ? null : (
              <Button
                variant="light"
                className={matchDetailsEditButton}
                onClick={() => setShowSetup(true)}
              >
                {editIcon}
              </Button>
            )}
          </Col>
        </Row>
      </section>
    );
  };

  const teamScoresheetBody = (teamA, teamB) => {
    const lineupMaxes = apparatusArray.map((a, i) => {
      if (teamA?.individuals || teamB?.individuals) {
        return Math.max(
          teamA?.events?.[i]?.lineup?.length || lineupMax,
          teamB?.events?.[i]?.lineup?.length || lineupMax,
          lineupMax
        );
      }

      return lineupMax;
    });

    return (
      <main className={body}>
        <Row className={row}>
          <Col className={squadLeft}>
            {apparatusArray.map((a, i) => (
              <div key={i}>
                {ScoreSheetLineup({
                  squad: teamA?.events?.[i]?.lineup,
                  showSidebar: true,
                  apparatus: a,
                  max: lineupMaxes?.[i],
                  isIndividuals: teamA?.individuals,
                  teamsByAthleteId,
                })}
                {ScoreSheetRounds({
                  data: !teamA?.individuals ? teamA?.events?.[i] : null,
                  apparatus: a,
                })}
              </div>
            ))}
          </Col>
          <Col className={squadRight}>
            {apparatusArray.map((a, i) => (
              <div key={i}>
                {ScoreSheetLineup({
                  squad: teamB?.events?.[i]?.lineup,
                  showSidebar: false,
                  apparatus: a,
                  max: lineupMaxes?.[i],
                  isIndividuals: teamB?.individuals,
                  teamsByAthleteId,
                })}
                {ScoreSheetRounds({
                  data: !teamB?.individuals ? teamB?.events?.[i] : null,
                  apparatus: a,
                })}
              </div>
            ))}
          </Col>
        </Row>
        <Row>
          <Col className={squadLeft}>
            <Row className={teamScore}>
              <Col className={teamScoreLabel}>
                {!!teamA?.neutral ? 'Team ND: ' : null}
              </Col>
              <Col
                className={
                  !!teamA.neutral ? teamScoreValue : teamScoreValueEmpty
                }
              >
                {!!teamA?.neutral
                  ? convertMillipointsToDisplay(teamA.neutral, 1)
                  : null}
              </Col>
              {!!teamA && (
                <>
                  <Col className={teamScoreLabel}>Final Score: </Col>
                  <Col className={teamScoreValue}>
                    {!teamA?.individuals ? teamA?.finalScore : null}
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Col className={squadRight}>
            <Row className={teamScore}>
              <Col className={teamScoreLabel}>
                {!!teamB?.neutral ? 'Team ND: ' : null}
              </Col>
              <Col
                className={
                  !!teamB?.neutral ? teamScoreValue : teamScoreValueEmpty
                }
              >
                {!!teamB?.neutral
                  ? convertMillipointsToDisplay(teamB.neutral, 1)
                  : null}
              </Col>
              {!!teamB && (
                <>
                  <Col className={teamScoreLabel}>Final Score: </Col>
                  <Col className={teamScoreValue}>
                    {!teamB?.individuals ? teamB?.finalScore : null}
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
        <Row className={row}>
          <Col className={squadLeft}>
            {ScoreSheetAA({
              data: teamA?.aa,
              showSidebar: true,
              apparatus: apparatusArray,
              max: lineupMax,
            })}
          </Col>
          <Col className={squadRight}>
            {ScoreSheetAA({
              data: teamB?.aa,
              showSidebar: false,
              apparatus: apparatusArray,
              max: lineupMax,
            })}
          </Col>
        </Row>
      </main>
    );
  };

  function TeamScoresheetFooter({ teamA, teamB, page }) {
    const { hideJudgeNames } = useContext(ScoreSheetContext);
    const evalConfig = useEvalConfig();
    const { dPanel, ePanel, jPanel } = evalConfig;

    const rankIt = (scoreTypes) => {
      const judgeRanking = [...dPanel, ...ePanel, ...jPanel];
      let rank = judgeRanking.length;

      for (let i = 0; i < judgeRanking.length; i++) {
        if (scoreTypes.includes(judgeRanking[i].type)) {
          rank = i;
          break;
        }
      }

      return rank;
    };

    return (
      <section className={footer}>
        <section className={subFooter}>
          <Row className={row}>
            <Col className={signature} style={{ display: 'block' }}>
              <h5>Signatures:</h5>
              <span className={headJudge}>* Head Judge</span>
            </Col>
          </Row>
          <Row className={row}>
            {apparatusArray.map((a, i) => (
              <Col key={i} className={signature}>
                <div className={signatureLabel}>
                  {`${a}: `}
                  <sup>*</sup>
                </div>
                <div className={signatureField}></div>
              </Col>
            ))}
          </Row>
          <Row className={row}>
            {apparatusArray.map((a, i) => {
              const headJudge = judgesByApparatus[a].headJudge;

              return (
                <Col key={i} className={signatureNames}>
                  {!!headJudge && (
                    <>
                      <div className={signatureLabel}></div>
                      <div className={signatureName}>
                        {!hideJudgeNames
                          ? headJudge.sessionJudgeAssignment.judge.name
                          : ''}{' '}
                        {!hideJudgeNames
                          ? getJudgeDisplayContext(headJudge, gender)
                          : ''}
                      </div>
                    </>
                  )}
                </Col>
              );
            })}
          </Row>

          {Array(maxJudges || 1)
            .fill('a')
            .map((_, index) => {
              return (
                <Fragment key={index}>
                  <Row key={index} className={row}>
                    {apparatusArray.map((a, i) => (
                      <Col key={i} className={signature}>
                        <div className={signatureLabel}>{`${a}: `}</div>
                        <div className={signatureField}></div>
                      </Col>
                    ))}
                  </Row>
                  <Row className={row}>
                    {apparatusArray.map((a, i) => {
                      const judge = Object.values(
                        judgesByApparatus[a].rest
                      ).sort((a, b) => {
                        if (rankIt(a.scoreTypes) < rankIt(b.scoreTypes)) {
                          return -1;
                        }
                        if (rankIt(a.scoreTypes) > rankIt(b.scoreTypes)) {
                          return 1;
                        }
                        return 0;
                      })[index];
                      const judgeName =
                        !!judge && judge.sessionJudgeAssignment.judge.name;
                      const judgeQual =
                        !!judge && getJudgeDisplayContext(judge, gender);

                      return (
                        <Col key={i} className={signatureNames}>
                          <div className={signatureLabel}></div>
                          <div className={signatureName}>
                            {!hideJudgeNames ? judgeName : ''}{' '}
                            {!hideJudgeNames ? judgeQual : ''}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Fragment>
              );
            })}
        </section>
        <section className={subFooter}>
          <Row className={row}>
            <Col className={signature}>
              <div className={signatureLabel}>{`Coach: `}</div>
              <div className={signatureField}></div>
            </Col>
            <Col className={signature}>
              <div className={signatureLabel}>{`Coach: `}</div>
              <div className={signatureField}></div>
            </Col>
          </Row>
          <Row className={row}>
            <Col className={signatureNames}>
              <div className={signatureLabel}></div>
              <div className={signatureName}>
                {!!teamA?.name
                  ? `${
                      coachData?.[session.gender]?.[teamA?.rtnId]?.headCoach ??
                      coachData['C1'].name
                    } (${
                      teamA?.forceTitle
                        ? `${teamA?.altName}: ${teamA?.displayName}`
                        : teamA?.altName
                    })`
                  : null}
              </div>
            </Col>
            <Col className={signatureNames}>
              <div className={signatureLabel}></div>

              <div className={signatureName}>
                {!!teamB?.name
                  ? `${
                      coachData?.[session.gender]?.[teamB?.rtnId]?.headCoach ??
                      coachData['C2'].name
                    } (${
                      teamB?.forceTitle
                        ? `${teamB?.altName}: ${teamB?.displayName}`
                        : teamB?.altName
                    })`
                  : null}
              </div>
            </Col>
          </Row>
          <Row className={row}>
            <Col className={signatureNames}>
              <div className={signatureLabel} />
              <div className={signatureName}>
                {teamA?.conductCard
                  ? `Yellow Card: ${teamA?.conductCard}`
                  : null}
              </div>
            </Col>
            <Col className={signatureNames}>
              <div className={signatureLabel} />
              <div className={signatureName}>
                {teamB?.conductCard
                  ? `Yellow Card: ${teamB?.conductCard}`
                  : null}
              </div>
            </Col>
          </Row>
        </section>
        {copyrightTimePageFooter(page)}
      </section>
    );
  }

  const TeamScoreSheet = (options, page) => {
    const indices = (options && options.split('_')) || '';
    let aIndex = indices.length > 0 ? parseInt(indices[0]) ?? null : null;
    let bIndex = indices.length > 1 ? parseInt(indices[1]) ?? null : null;
    const { ePanel, jPanel } = evalConfig;
    const isReduceFont =
      Math.max(jPanel?.length, ePanel?.length) > 4 ? reduceFont : null;

    // Override with custom settings
    if (teamAIndex !== null) {
      aIndex = teamAIndex;
    }

    if (teamBIndex !== null) {
      bIndex = teamBIndex;
    }

    const teamA = aIndex < teams.length ? teams[aIndex] : null;
    const teamB = bIndex < teams.length ? teams[bIndex] : null;

    return (
      <div className={[teamScoreSheet, isReduceFont].join(' ')}>
        {teamScoresheetHeader(teamA, teamB)}
        {teamScoresheetBody(teamA, teamB)}
        <TeamScoresheetFooter teamA={teamA} teamB={teamB} page={page} />
      </div>
    );
  };

  const championshipsScoresheetHeader = (team) => {
    return (
      <section className={header}>
        <h1>{format.title}</h1>
        <Row className={row}>
          <Col className={teamName}>
            <div className={teamNameLabel}>Team: </div>
            {/* <div className={teamNameTeam}>
              {' '}
              {team && team?.forceTitle
                ? `${team?.altName}: ${team?.displayName}`
                : team?.altName}
            </div> */}
            <div className={teamNameTeam}>
              {(team && team?.forceTitle
                ? `${team?.altName}: ${team?.displayName}`
                : team?.altName) || ''}
              {team?.individuals ? ' (Individuals)' : ''}
              {/* {team?.title} */}
            </div>
          </Col>
          <Col className={teamName}>
            <div className={teamNameLabel}>Coach: </div>
            <div className={teamNameTeam}>
              {coachData?.[session.gender]?.[team?.rtnId]?.headCoach ??
                coachData['C1'].name}
            </div>
          </Col>
        </Row>
        <Row className={row}>
          <Col className={matchDetailsShort}>
            <div className={matchDetailsLabel}>Date: </div>
            <div className={matchDetailsShortField}>{`${dateFormat(
              new Date(date),
              "M'/'dd'/'yyyy"
            )}`}</div>
          </Col>
          <Col className={matchDetailsLong}>
            <div className={matchDetailsLabel}>Location: </div>
            <div className={matchDetailsLongField}>{location}</div>
          </Col>
          <Col className={matchDetailsShort}>
            <div className={matchDetailsLabel}>Attendance: </div>
            <div className={matchDetailsShortField}>{attendance}</div>
          </Col>
        </Row>
      </section>
    );
  };

  const championshipsScoresheetBody = (team) => {
    const lineupMaxes = apparatusArray.map((a, i) => {
      if (team?.individuals) {
        return Math.max(
          team?.events?.[i]?.lineup?.length || lineupMax,
          lineupMax
        );
      }
      return lineupMax;
    });

    return (
      <main className={body}>
        <Row className={row}>
          <Col className={squadLeft} xs={8}>
            {apparatusArray.map((a, i) => (
              <div key={i}>
                {ScoreSheetLineup({
                  squad: team?.events?.[i]?.lineup,
                  showSidebar: true,
                  apparatus: a,
                  max: lineupMaxes?.[i],
                })}
                {ScoreSheetRounds({
                  data: !team?.individuals ? team?.events?.[i] : null,
                  apparatus: a,
                })}
              </div>
            ))}
          </Col>
          <Col className={squadRight} xs={4}>
            {apparatusArray.map((a, i) => (
              <div key={i}>
                {ScoreSheetJudgeLineup({
                  //squad: judgesByApparatus[a],
                  judges: judgesByApparatus[a],
                  showSidebar: false,
                  apparatus: a,
                  max: lineupMaxes?.[i],
                  gender,
                })}
                <Row className={rotationScore}>&nbsp;</Row>
              </div>
            ))}
          </Col>
        </Row>
        <Row>
          <Col className={squadLeft} xs={8}>
            <Row className={teamScore}>
              <Col className={teamScoreLabel}>
                {!!team?.neutral ? 'Team ND: ' : null}
              </Col>
              <Col
                className={
                  !!team?.neutral ? teamScoreValue : teamScoreValueEmpty
                }
              >
                {!!team?.neutral
                  ? convertMillipointsToDisplay(team?.neutral, 1)
                  : null}
              </Col>
              <Col className={teamScoreLabel}>Final Score: </Col>
              <Col className={teamScoreValue}>
                {!team?.individuals ? team?.finalScore : null}
              </Col>
            </Row>
          </Col>
          <Col className={squadRight} xs={4}></Col>
        </Row>
        <Row className={row}>
          <Col className={squadFull}>
            {ScoreSheetAA({
              data: team?.aa,
              showSidebar: true,
              apparatus: apparatusArray,
              max: lineupMax,
            })}
          </Col>
        </Row>
      </main>
    );
  };

  const championshipsScoresheetFooter = (team, page) => {
    return (
      <section className={footer}>
        <section className={subFooter}>
          <Row className={row}>
            <Col className={signature}>
              <div className={signatureLabel}>{`Signature: `}</div>
              <div className={signatureField}></div>
            </Col>
            <Col className={yellowCard}>
              <Form.Check
                type="checkbox"
                label="Yellow Card"
                id="yellowCard"
                checked={!!team?.conductCard}
                readOnly
              />
            </Col>
          </Row>
          <Row className={row}>
            <Col className={signatureNames}>
              <div className={signatureLabel}></div>
            </Col>
            <Col className={signatureNames}>
              <div className={signatureLabel}></div>
            </Col>
          </Row>
        </section>
        {copyrightTimePageFooter(page)}
      </section>
    );
  };

  const ChampionshipsScoreSheet = (options, page) => {
    let aIndex =
      options && options.length > 0 ? parseInt(options) ?? null : null;

    // Override with custom settings
    if (teamAIndex !== null) {
      aIndex = teamAIndex;
    }

    const teamA = aIndex < teams.length ? teams[aIndex] : {};

    return (
      <>
        {championshipsScoresheetHeader(teamA)}
        {championshipsScoresheetBody(teamA)}
        {championshipsScoresheetFooter(teamA, page)}
      </>
    );
  };

  const teamSummaryScoresheetHeader = (
    <section className={header}>
      <h1>
        <span className={virtiusTitle}>
          <span>V</span>irtius&nbsp;
        </span>
        <span style={{ fontWeight: '700' }}>Score Sheet</span>
      </h1>
      <h2>
        TEAM RESULTS
        {altTeamScoring ? ` (${prettyTeamScoring(altTeamScoring)})` : null}
        {combineKey || combineKeys.length > 0 ? ` (Combined Sessions)` : null}
      </h2>
      <section>
        <Row className={row}>
          <Col className={sessionInfo}>
            <div className={sessionInfoLabel}>
              Session{combineKey || combineKeys.length > 0 ? '(s)' : null}
              :&nbsp;
            </div>
            <div className={sessionInfoValue}>
              {sessionName}
              {combineKey ? ` + ${combineSession?.session?.name}` : null}
              {combineKeys.length > 0
                ? ` + ${combinedSessions
                    .filter((s) => s)
                    .map((s) => s.name)
                    .join(' + ')}`
                : null}
            </div>
          </Col>
        </Row>
        <Row className={row}>
          <Col className={sessionInfo}>
            <div className={sessionInfoLabel}>Date: </div>
            <div className={sessionInfoValue}>{`${dateFormat(
              new Date(date),
              "M'/'dd'/'yyyy"
            )}`}</div>
          </Col>
        </Row>
        <Row className={row}>
          <Col className={sessionInfo}>
            <div className={sessionInfoLabel}>Location: </div>
            <div className={sessionInfoValue}>{location}</div>
          </Col>
        </Row>
      </section>
    </section>
  );

  const teamSummaryScoresheetBody = (
    <main className={body}>
      <Row className={row}>
        <Col>
          {TeamSummaryScoreSheetTable({
            teams,
            apparatus: apparatusArray,
          })}
        </Col>
      </Row>
    </main>
  );

  const basicScoresheetFooter = (page) => {
    return (
      <section className={footer}>{copyrightTimePageFooter(page)}</section>
    );
  };

  const TeamSummaryScoreSheet = (page) => {
    return (
      <>
        {teamSummaryScoresheetHeader}
        {teamSummaryScoresheetBody}
        {basicScoresheetFooter(page)}
      </>
    );
  };

  const aaScoresheetHeader = (
    <section className={header}>
      <h1>
        <span className={virtiusTitle}>
          <span>V</span>irtius&nbsp;
        </span>
        <span style={{ fontWeight: '700' }}>Score Sheet</span>
      </h1>
      <h2>
        ALL-AROUND RESULTS
        {combineKey || combineKeys.length > 0 ? ` (Combined Sessions)` : null}
      </h2>
      <section>
        <Row className={row}>
          <Col className={sessionInfo}>
            <div className={sessionInfoLabel}>
              Session{combineKey || combineKeys.length > 0 ? '(s)' : null}
              :&nbsp;
            </div>
            <div className={sessionInfoValue}>
              {sessionName}
              {combineKey ? ` + ${combineSession?.session?.name}` : null}
              {combineKeys.length > 0
                ? ` + ${combinedSessions
                    .filter((s) => s)
                    .map((s) => s.name)
                    .join(' + ')}`
                : null}
            </div>
          </Col>
        </Row>
        <Row className={row}>
          <Col className={sessionInfo}>
            <div className={sessionInfoLabel}>Date: </div>
            <div className={sessionInfoValue}>{`${dateFormat(
              new Date(date),
              "M'/'dd'/'yyyy"
            )}`}</div>
          </Col>
        </Row>
        <Row className={row}>
          <Col className={sessionInfo}>
            <div className={sessionInfoLabel}>Location: </div>
            <div className={sessionInfoValue}>{location}</div>
          </Col>
        </Row>
      </section>
    </section>
  );

  const aaScoresheetBody = (
    <main className={body}>
      <Row className={row}>
        <Col>
          {AAScoreSheetTable({
            individuals: individuals['AA'],
            apparatus: apparatusArray,
          })}
        </Col>
      </Row>
    </main>
  );

  const AAScoreSheet = (page) => {
    return (
      <>
        {aaScoresheetHeader}
        {aaScoresheetBody}
        {basicScoresheetFooter(page)}
      </>
    );
  };

  const apparatusScoresheetHeader = (apparatus) => {
    return (
      <section className={header}>
        <h1>
          <span className={virtiusTitle}>
            <span>V</span>irtius&nbsp;
          </span>
          <span style={{ fontWeight: '700' }}>Score Sheet</span>
        </h1>
        <h2>
          {abbvToApparatusName[apparatus].toUpperCase()} RESULTS
          {combineKey || combineKeys.length > 0 ? ` (Combined Sessions)` : null}
        </h2>
        <section>
          <Row className={row}>
            <Col className={sessionInfo}>
              <div className={sessionInfoLabel}>
                Session{combineKey || combineKeys.length > 0 ? '(s)' : null}
                :&nbsp;
              </div>
              <div className={sessionInfoValue}>
                {sessionName}
                {combineKey ? ` + ${combineSession?.session?.name}` : null}
                {combineKeys.length > 0
                  ? ` + ${combinedSessions
                      .filter((s) => s)
                      .map((s) => s.name)
                      .join(' + ')}`
                  : null}
              </div>
            </Col>
          </Row>
          <Row className={row}>
            <Col className={sessionInfo}>
              <div className={sessionInfoLabel}>Date: </div>
              <div className={sessionInfoValue}>{`${dateFormat(
                new Date(date),
                "M'/'dd'/'yyyy"
              )}`}</div>
            </Col>
          </Row>
          <Row className={row}>
            <Col className={sessionInfo}>
              <div className={sessionInfoLabel}>Location: </div>
              <div className={sessionInfoValue}>{location}</div>
            </Col>
          </Row>
        </section>
      </section>
    );
  };

  const apparatusScoresheetBody = (apparatus) => {
    return (
      <main className={body}>
        <Row className={row}>
          <Col>
            {ApparatusScoreSheetTable({
              individuals: individuals[apparatus],
              apparatus: apparatusArray,
              teamsByAthleteId,
            })}
          </Col>
        </Row>
      </main>
    );
  };

  const ApparatusScoreSheet = (apparatus, page) => {
    return (
      <>
        {apparatusScoresheetHeader(apparatus)}
        {apparatusScoresheetBody(apparatus)}
        {basicScoresheetFooter(page)}
      </>
    );
  };

  const teamBreakdownScoresheetHeader = (
    <section className={header}>
      <h1>
        <span className={virtiusTitle}>
          <span>V</span>irtius&nbsp;
        </span>
        <span style={{ fontWeight: '700' }}>Score Sheet</span>
      </h1>
      <h2>
        TEAM BREAKDOWN
        {altTeamScoring ? ` (${prettyTeamScoring(altTeamScoring)})` : null}
        {combineKey || combineKeys.length > 0 ? ` (Combined Sessions)` : null}
      </h2>
      <section>
        <Row className={row}>
          <Col className={sessionInfo}>
            <div className={sessionInfoLabel}>
              Session{combineKey || combineKeys.length > 0 ? '(s)' : null}
              :&nbsp;
            </div>
            <div className={sessionInfoValue}>
              {sessionName}
              {combineKey ? ` + ${combineSession?.session?.name}` : null}
              {combineKeys.length > 0
                ? ` + ${combinedSessions
                    .filter((s) => s)
                    .map((s) => s.name)
                    .join(' + ')}`
                : null}
            </div>
          </Col>
        </Row>
        <Row className={row}>
          <Col className={sessionInfo}>
            <div className={sessionInfoLabel}>Date: </div>
            <div className={sessionInfoValue}>{`${dateFormat(
              new Date(date),
              "M'/'dd'/'yyyy"
            )}`}</div>
          </Col>
        </Row>
        <Row className={row}>
          <Col className={sessionInfo}>
            <div className={sessionInfoLabel}>Location: </div>
            <div className={sessionInfoValue}>{location}</div>
          </Col>
        </Row>
      </section>
    </section>
  );

  const teamBreakdownScoresheetBody = (teams) => {
    return (
      <main className={body}>
        {teams.map((t, i) => {
          return (
            <Row
              className={row}
              key={`team_${i}`}
              style={{ marginBottom: i === 0 ? '20pt' : null }}
            >
              <Col>
                {TeamBreakdownScoreSheetTable({
                  team: t,
                  apparatus: apparatusArray,
                  teamsByAthleteId,
                })}
              </Col>
            </Row>
          );
        })}
      </main>
    );
  };

  const TeamBreakdownScoreSheet = (options, page) => {
    const indices = (options && options.split('_')) || '';
    let aIndex = indices.length > 0 ? parseInt(indices[0]) ?? null : null;
    let bIndex = indices.length > 1 ? parseInt(indices[1]) ?? null : null;

    // Override with custom settings
    if (teamAIndex !== null) {
      aIndex = teamAIndex;
    }

    if (teamBIndex !== null) {
      bIndex = teamBIndex;
    }

    const teamA = aIndex < teams.length ? teams[aIndex] : null;
    const teamB = bIndex < teams.length ? teams[bIndex] : null;

    return (
      <>
        {teamBreakdownScoresheetHeader}
        {teamBreakdownScoresheetBody([teamA, teamB])}
        {basicScoresheetFooter(page)}
      </>
    );
  };

  const lineupVerificationFormHeader = (team) => {
    return (
      <section className={header}>
        <h1>
          <span className={virtiusTitle}>
            <span>V</span>irtius&nbsp;
          </span>
          <span style={{ fontWeight: '700' }}>Competition Form</span>
        </h1>
        <h2>LINEUP VERIFICATION ({team?.displayName})</h2>
        <section>
          <Row className={row}>
            <Col className={sessionInfo}>
              <div className={sessionInfoLabel}>
                Session{combineKey ? '(s)' : null}:&nbsp;
              </div>
              <div className={sessionInfoValue}>
                {sessionName}
                {combineKey ? ` + ${combineSession?.session?.name}` : null}
              </div>
            </Col>
          </Row>
          <Row className={row}>
            <Col className={sessionInfo}>
              <div className={sessionInfoLabel}>Date: </div>
              <div className={sessionInfoValue}>{`${dateFormat(
                new Date(date),
                "M'/'dd'/'yyyy"
              )}`}</div>
            </Col>
          </Row>
          <Row className={row}>
            <Col className={sessionInfo}>
              <div className={sessionInfoLabel}>Location: </div>
              <div className={sessionInfoValue}>{location}</div>
            </Col>
          </Row>
        </section>
      </section>
    );
  };

  const LineupVerificationSign = (
    <section className={footer}>
      <section className={subFooter}>
        <Row className={row}>
          <Col className={signature} xs={2} />
          <Col
            className={signature}
            xs={2}
            style={{ flexDirection: 'row-reverse' }}
          >
            <div
              className={signatureLabel}
              style={{ textWrap: 'nowrap', flexDirection: 'row-reverse' }}
            >{`Changed: `}</div>
          </Col>
          <Col className={yellowCard} xs={1}>
            <Form.Check
              type="checkbox"
              label="&nbsp;&nbsp;Yes"
              id="lineupChangedYes"
              // checked={false}
            />
          </Col>
          <Col className={yellowCard} xs={1}>
            <Form.Check
              type="checkbox"
              label="&nbsp;&nbsp;No"
              id="lineupChangedNo"
              //checked={false}
            />
          </Col>
          <Col className={signature} xs={4}>
            <div
              className={signatureLabel}
              style={{ whiteSpace: 'nowrap' }}
            >{`Coach Initials: `}</div>
            <div className={signatureField}></div>
          </Col>
          <Col className={signature} xs={2} />
        </Row>
      </section>
    </section>
  );

  const lineupVerificationFormBody = (team) => {
    const numApparatus = apparatusArray.length;
    return (
      <main className={body}>
        <Row className={row}>
          <Col>
            {LineupVerificationFormTable({
              team,
              apparatus: apparatusArray.slice(0, numApparatus / 2),
            })}
          </Col>
        </Row>
        <Row className={row}>
          <Col>
            {LineupVerificationFormTable({
              team,
              apparatus: apparatusArray.slice(numApparatus / 2, numApparatus),
            })}
          </Col>
        </Row>
        <Row className={row}>
          <Col>{LineupVerificationSign}</Col>
        </Row>
      </main>
    );
  };

  const LineupVerificationForm = (options, page) => {
    return (
      <div className={lineupForm}>
        {lineupVerificationFormHeader(teams[options])}
        {lineupVerificationFormBody(teams[options])}
        {basicScoresheetFooter(page)}
      </div>
    );
  };

  function ScoreSheet({ selector, page }) {
    const {
      TEAM,
      CHAMPIONSHIPS,
      SUMMARY,
      AA,
      APPARATUS,
      BREAKDOWN,
      LINEUP,
    } = SCORESHEET_TYPES;

    const isApparatus = apparatusArray.includes(selector);
    const isTeam = selector.includes(TEAM);
    const isChampionships = selector.includes(CHAMPIONSHIPS);
    const isBreakdown = selector.includes(BREAKDOWN);
    const isLineup = selector.includes(LINEUP);

    let options = '';
    let subSelector = selector;

    if (isApparatus) {
      subSelector = APPARATUS;
      options = selector;
    }

    if (isTeam) {
      subSelector = TEAM;
      options = selector.replace(TEAM, '');
    }

    if (isChampionships) {
      subSelector = CHAMPIONSHIPS;
      options = selector.replace(CHAMPIONSHIPS, '');
    }

    if (isBreakdown) {
      subSelector = BREAKDOWN;
      options = selector.replace(BREAKDOWN, '');
    }

    if (isLineup) {
      subSelector = LINEUP;
      options = selector.replace(LINEUP, '');
    }

    switch (subSelector) {
      case TEAM:
        return TeamScoreSheet(options, page);
      case SUMMARY:
        return TeamSummaryScoreSheet(page);
      case CHAMPIONSHIPS:
        return ChampionshipsScoreSheet(options, page);
      case AA:
        return AAScoreSheet(page);
      case APPARATUS:
        return ApparatusScoreSheet(options, page);
      case BREAKDOWN:
        return TeamBreakdownScoreSheet(options, page);
      case LINEUP:
        return LineupVerificationForm(options, page);
      default:
        return TeamScoreSheet(options, page);
    }
  }

  // console.log(sheetIndex);
  // console.log(sheetOptions);

  const scoresheetRefs = useState([]);

  function SingleScoreSheet({ sheet, page, currentPage }) {
    const { singleSheetOnly } = useContext(ScoreSheetContext);
    const ref = useRef();
    const { ref: inViewRef, inView } = useInView({
      threshold: 0.25,
      delay: 500,
    });

    const setRefs = useCallback(
      (node) => {
        ref.current = node;
        inViewRef(node);
        scoresheetRefs[page - 1] = ref.current;
      },
      [inViewRef]
    );

    const handleIntersection = (entries) => {
      let mostImportantElement = null;
      let mostImportantOverlap = 0;
      let mostImportantIndex = null;

      entries.forEach((entry, index) => {
        if (!entry?.getBoundingClientRect) {
          return;
        }

        const vpOffset = entry.getBoundingClientRect();
        const vpHeight = window.innerHeight;
        const inView =
          (vpOffset.top > 0 && vpOffset.top < vpHeight) ||
          (vpOffset.bottom < vpHeight && vpOffset.bottom > 0) ||
          (vpOffset.top < 0 && vpOffset.bottom > vpHeight);

        const verticalIntersection = Math.max(
          0,
          Math.min(vpOffset.bottom, window.innerHeight) -
            Math.max(vpOffset.top, 0)
        );
        const verticalOverlapPercentage =
          (verticalIntersection / vpOffset.height) * 100;

        if (inView) {
          if (
            !mostImportantElement ||
            verticalOverlapPercentage > mostImportantOverlap
          ) {
            mostImportantElement = entry;
            mostImportantOverlap = verticalOverlapPercentage;
            mostImportantIndex = index;
          }
        }
      });

      return {
        index: mostImportantIndex,
        element: mostImportantElement,
        overlap: mostImportantOverlap,
      };
    };

    useEffect(() => {
      if (inView && !toggleNextSheet) {
        const { index } = handleIntersection(scoresheetRefs);
        if (index !== null) {
          setSheetIndex(index);
        }
      }
    }, [inView, toggleNextSheet]);

    const showSheet =
      (singleSheetOnly && page === currentPage) || !singleSheetOnly;

    return !showSheet ? null : (
      <div
        ref={setRefs}
        id={`scoresheet_${page}`}
        className={[
          main,
          genderClass,
          'scoresheet',
          page === 1 ? first : null,
        ].join(' ')}
      >
        <ScoreSheet selector={sheet} page={page} />
      </div>
    );
  }

  function ScoreSheetSet() {
    return (
      <>
        {sheetOptions.map((sheet, page) => {
          return (
            <SingleScoreSheet
              key={`scoresheet_${page + 1}`}
              sheet={sheet}
              page={page + 1}
              currentPage={sheetIndex + 1}
            />
          );
        })}
      </>
    );
  }

  function ScoreSheetHeader() {
    const { hideJudgeNames } = useContext(ScoreSheetContext);

    return (
      <Header className={noPrint}>
        <MenuBubble />
        <ScoreSheetSetupButton handler={() => setShowSetup(true)} />
        <ScoreSheetPrintButton
          handler={() =>
            exportPDF({
              sessionId: sessionKey,
              sessionName,
              dispatch,
              combineKey,
              combineKeys,
              timezone,
              hideJudgeNames: noJudge || hideJudgeNames,
              lineupVerification: lineupVerification || lineupVerificationMode,
              singleSheet: singleSheetOnly || singleSheet,
            })
          }
        />
        <ScoreSheetPrevButton disabled={sheetIndex === 0} handler={handleUp} />
        <ScoreSheetNextButton
          disabled={sheetIndex === sheetOptions.length - 1}
          handler={handleDown}
        />
        <ScrollShadow pct={((sheetIndex + 1) / sheetOptions.length) * 100} />
        <UserBubble />
      </Header>
    );
  }

  return (
    <>
      <ScoreSheetHeader />
      <ScoreSheetSet />
      <ScoreSheetSetup
        show={showSetup}
        setShow={setShowSetup}
        teams={teams}
        teamAIndex={teamAIndex}
        teamBIndex={teamBIndex}
        setTeamAIndex={setTeamAIndex}
        setTeamBIndex={setTeamBIndex}
        location={location}
        attendance={attendance}
        setLocation={setLocation}
        setAttendance={setAttendance}
        sessionId={sessionId}
        sessionVersion={session._version}
        altTeamScoring={altTeamScoring}
        setAltTeamScoring={setAltTeamScoring}
        setCombineKey={setCombineKey}
        setCombineSessions={setCombineSessions}
        combineSessions={combineSessions}
        combineSession={combineSession}
        combineKey={combineKey}
        combineLoaded={combineLoaded}
        combinedSessionsLoaded={combinedSessionsLoaded}
        setCombinedSessionsLoaded={setCombinedSessionsLoaded}
        combineKeys={combineKeys}
        setCombineKeys={setCombineKeys}
        combinedSessions={combinedSessions}
      />
      <Footer hide={showSetup} />
    </>
  );
}
