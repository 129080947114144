import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useOverlaySync } from '../../hooks/useOverlaySync';
import UserBubble from '../helpers/userbubble';
import MenuBubble from '../helpers/menububble';
import VideoPlayer from './videoplayer';
import TriggerPanel from './triggerpanel';
import CompetitorList from './competitorlist';
import ScoreDeltas from './scoredeltas';
import RoundDeltas from './rounddeltas';
import RoundSummary from './roundsummary';
import SessionFooter from './sessionfooter';
import SessionHeader from './sessionheader';
import TeamStripes from './teamstripes';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../helpers/header';
import MatchCountdown from '../helpers/matchcountdown';
import { Scoreboard } from './scoreboard';
import {
  sessionViewType,
  sessionStatus,
  sessionRoleType,
  sessionType,
} from '../../redux/_constants';
import { dualTeamBRotation } from '../../utilities/session';
import { SessionSubscriptionManager } from './hooks';
import './main.css';
import { generateSessionUrl } from '../../utilities/generate-session-url';
import { useClips } from '../../utilities/clips';
import { useHotkeys } from 'react-hotkeys-hook';
import { Html2Image } from '../helpers/html2image';
import { producerActions } from '../../redux/_actions';
import { SessionTeamScoreType } from '../../models';
import CookieBanner from '../../utilities/cookie';

const {
  SCOREBOARD,
  FULLSCREEN,
  FULLSCREENA,
  FULLSCREENB,
  MOBILE,
  MIRRORA,
  MIRRORB,
  SOLOPLAYER,
  DUALLINEUP,
  SOLOLINEUP,
  DEFAULT,
  PLAYERDUAL,
} = sessionViewType;

const { SOLO } = sessionType;

const { ADMIN, JUDGE } = sessionRoleType;

export default function Main({
  sessionId,
  isDarkMode,
  isLeaderBoard,
  size,
  streamType,
  leaderboardType,
}) {
  const round = useSelector((state) => state.producer.round);
  const showLeaderboard = useSelector((state) => state.producer.leaderboard);
  const view = useSelector((state) => state.session.view);
  const role = useSelector((state) => state.session.role);
  const type = useSelector((state) => state.session.type);
  const teamScoring = useSelector((state) => state.session.teamScoring);
  const isLive = useSelector(
    (state) => state.session.status === sessionStatus.LIVE
  );
  const isPost = useSelector(
    (state) => state.session.status === sessionStatus.POSTGAME
  );
  const streamCount = useSelector(
    (state) => state.session.streams.items.length
  );
  const isPregame = useSelector((state) =>
    [sessionStatus.CREATED, sessionStatus.PREGAME].includes(
      state.session.status
    )
  );
  const alternating = useSelector((state) => state.session.alternating);
  const numRounds = useSelector(
    (state) => state.session.apparatus.split('1').length - 1
  );
  const sessionKey = useSelector((state) => state.session.sessionKey);
  const [showSummary, setShowSummary] = useState(false);
  const [darkMode, setDarkMode] = useState(isDarkMode);
  const [fontMode, setFontMode] = useState(false);
  const isScoreboardView = view === SCOREBOARD;
  const isFullscreen = [FULLSCREEN, FULLSCREENA, FULLSCREENB].includes(view);
  const isLineupView = [SOLOLINEUP, DUALLINEUP].includes(view);
  const isMobile = view === MOBILE;
  const isAdmin = role === ADMIN;
  const isJudge = role === JUDGE;
  const showRoundSummary = teamScoring !== SessionTeamScoreType.NONE;
  const teamBAlternating = alternating && isLive && round % 2 === 0;
  const isMirrorA = view === MIRRORA;
  const isMirrorB = view === MIRRORB;
  const isSoloPlayer = [SOLOPLAYER, MOBILE, DEFAULT, PLAYERDUAL].includes(view);
  const teamA = useSelector(
    (state) => state.producer[isMirrorB ? 'teamB' : 'teamA']
  );
  const teamB = useSelector(
    (state) => state.producer[isMirrorA ? 'teamA' : 'teamB']
  );
  const isSolo =
    type === SOLO || view === SOLOLINEUP || view === SOLOPLAYER || !teamB;

  const maxLineupLength = isSolo
    ? teamA?.lineup.length
    : Math.max(teamA?.lineup?.length, teamB?.lineup?.length);

  // Used for postgame viewing
  const teamAClips = useClips(teamA?.lineupId, round);
  const teamBClips = useClips(
    teamB?.lineupId,
    alternating ? dualTeamBRotation(round) : round
  );

  const hasClips = teamAClips?.length + teamBClips?.length > 0;

  const dispatch = useDispatch();

  useEffect(() => {
    document.documentElement.setAttribute(
      'data-theme',
      isDarkMode ? 'dark' : null
    );
  }, [isDarkMode]);

  useEffect(() => {
    document.documentElement.setAttribute(
      'font-theme',
      String(size).toUpperCase() === 'LG' ? '700' : null
    );
  }, [size]);

  useHotkeys(
    's',
    (e) => {
      e.preventDefault();
      setShowSummary(!showSummary);
      if (showLeaderboard) {
        dispatch(producerActions.leaderboard(!showLeaderboard));
      }
    },
    [showSummary, showLeaderboard]
  );

  useHotkeys(
    'd',
    (e) => {
      e.preventDefault();
      if (isScoreboardView || isLeaderBoard) {
        document.documentElement.setAttribute(
          'data-theme',
          darkMode ? null : 'dark'
        );
        setDarkMode(!darkMode);
        console.log('Dark Mode: ', !darkMode);
      }
    },
    [darkMode, isScoreboardView]
  );

  useHotkeys(
    'f',
    (e) => {
      e.preventDefault();
      if (isScoreboardView || isLeaderBoard) {
        document.documentElement.setAttribute(
          'font-theme',
          fontMode ? null : '700'
        );
        setFontMode(!fontMode);
      }
    },
    [fontMode, isScoreboardView]
  );

  const videoRowDual = (
    <Row className={['players', teamBAlternating ? 'swap' : null].join(' ')}>
      <Col>
        <Row className="dualPlayerWrapper">
          {isPregame ? <MatchCountdown sessionId={sessionId} /> : null}
          <Col className="left">
            <VideoPlayer
              team={teamA}
              swap={teamBAlternating}
              mirror={isMirrorB}
              clips={teamAClips}
              streamType={streamType}
            />
          </Col>
          {/*<Col className="center" xs={0}></Col>*/}
          <Col className="right">
            <VideoPlayer
              team={teamB}
              swap={teamBAlternating}
              mirror={isMirrorA}
              clips={teamBClips}
              streamType={streamType}
            />
          </Col>
        </Row>
        <TriggerPanel />
      </Col>
    </Row>
  );

  const videoRowSolo = (
    <Row className="players">
      <Col>
        <Row>
          {isPregame ? <MatchCountdown sessionId={sessionId} /> : null}
          <VideoPlayer
            team={teamA}
            clips={teamAClips}
            streamType={streamType}
          />
        </Row>
        <TriggerPanel row />
      </Col>
    </Row>
  );

  const triggerPanelOnly = (
    <Row className="players">
      <Col>
        <TriggerPanel row />
      </Col>
    </Row>
  );

  const videoRow = isSoloPlayer ? videoRowSolo : videoRowDual;

  const lineups = (
    <Row className="lineups">
      <Col>
        <Row>
          {view === FULLSCREENB ? null : (
            <Col className={['left', isSolo ? 'soloLineup' : ''].join(' ')}>
              <CompetitorList team={teamA} left={true} max={maxLineupLength} />
              <hr />
              {!showRoundSummary ? null : (
                <RoundSummary team={teamA} round={round} left={true} />
              )}
            </Col>
          )}
          {isSolo ? null : (
            <Col className="center" xs={1}>
              <ScoreDeltas />
            </Col>
          )}
          {view === FULLSCREENA || isSolo ? null : (
            <Col className="right">
              <CompetitorList team={teamB} right={true} max={maxLineupLength} />
              <hr />
              {!showRoundSummary ? null : (
                <RoundSummary team={teamB} round={round} right={true} />
              )}
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );

  const allRounds = (
    <Row className="lineups">
      <Col>
        <Row>
          <Col className="left">
            {Array.from({ length: numRounds }, (_, index) => index + 1).map(
              (r, index) => {
                return (
                  <div key={`roundsummary-left-${index}`}>
                    <RoundSummary
                      team={teamA}
                      round={r}
                      left={true}
                      showName={true}
                    />
                    {index < numRounds - 1 ? <hr /> : null}
                  </div>
                );
              }
            )}
          </Col>
          <Col className="center" xs={1}>
            <RoundDeltas rounds={numRounds} />
          </Col>
          <Col className="right">
            {Array.from({ length: numRounds }, (_, index) => index + 1).map(
              (r, index) => {
                return (
                  <div key={`roundsummary-right-${index}`}>
                    <RoundSummary
                      team={teamB}
                      round={r}
                      right={true}
                      showName={true}
                    />
                    {index < numRounds - 1 ? <hr /> : null}
                  </div>
                );
              }
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <div
      className={[
        'vs',
        'main',
        'session',
        isMobile ? 'mobile' : null,
        isScoreboardView ? 'scoreboardView' : null,
      ].join(' ')}
    >
      <Header>
        {isScoreboardView ||
        isFullscreen ||
        isLeaderBoard ||
        isMobile ? null : (
          <UserBubble loginRedirectPath={generateSessionUrl(sessionKey)} />
        )}
        {isScoreboardView ||
        isFullscreen ||
        isLeaderBoard ||
        isMobile ? null : (
          <MenuBubble />
        )}
        {!isLeaderBoard && (
          <SessionHeader
            solo={isSolo}
            mirror={isMirrorA ? 'A' : isMirrorB ? 'B' : null}
          />
        )}
      </Header>
      <TeamStripes
        colorA={teamA.color}
        colorB={isSolo ? 'transparent' : teamB.color}
      />

      <Container
        className={
          isFullscreen || isLeaderBoard
            ? 'fullscreen'
            : isSoloPlayer
            ? 'soloPlayer'
            : 'dualPlayer'
        }
      >
        {isScoreboardView || isFullscreen || isLeaderBoard
          ? null
          : isLineupView ||
            (isLive && streamCount === 0) ||
            (isLive && isJudge) || // changed just for 1/15 event
            (isPost && streamCount === 0 && !hasClips)
          ? triggerPanelOnly
          : videoRow}

        {showSummary ? allRounds : lineups}
        <SessionFooter
          teamA={teamA}
          teamB={teamB}
          sessionId={sessionId}
          solo={isSolo}
        />
        <Scoreboard
          sessionId={sessionId}
          isLeaderBoard={isLeaderBoard}
          type={leaderboardType}
        />
      </Container>
      <Html2Image
        enabled={isAdmin}
        className={showLeaderboard ? 'modal' : 'main'}
      />
      <SessionSubscriptionManager sessionId={sessionId} />
      <CookieBanner />
    </div>
  );
}
