import React from 'react';
import Main from './main';
import MultiExperimental from './multiexperimental';
import { useOverlaySync } from '../../hooks/useOverlaySync';
import { useRoundSync } from '../../hooks/useRoundSync';
import { OverlaySubscriptions } from './hooks';

export function MultiExperimentalSync({ sessionId }) {
  const { syncCommand, initialized } = useOverlaySync(sessionId);
  useRoundSync(syncCommand);

  if (!initialized) {
    return <OverlaySubscriptions sessionId={sessionId} />;
  }

  return (
    <>
      <MultiExperimental sessionId={sessionId} />
      <OverlaySubscriptions sessionId={sessionId} />
    </>
  );
}

export function MainSync({ sessionId, isDarkMode, isLeaderBoard, streamType }) {
  const { syncCommand, initialized } = useOverlaySync(sessionId);
  useRoundSync(syncCommand);

  if (!initialized) {
    return <OverlaySubscriptions sessionId={sessionId} />;
  }

  return (
    <>
      <Main
        sessionId={sessionId}
        isDarkMode={isDarkMode}
        isLeaderBoard={isLeaderBoard}
        streamType={streamType}
      />
      <OverlaySubscriptions sessionId={sessionId} />
    </>
  );
}
