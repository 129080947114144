const week2 = [
  {
    tags: ['MALE'],
    sessionId: '5QrEsXnwQI',
    gameTime: '2025-01-11T18:00:00.000Z',
    title: 'Michigan / Greenville / Simpson',
    teamA: 'Michigan',
    teamALogo: 'upload/images/team/OlF6zJbX8C_3TY7v_vL5S',
    teamB: 'Greenville',
    teamBLogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
    teamC: 'Simpson',
    teamCLogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
  },
];

const week3 = [
  {
    tags: ['MALE'],
    sessionId: 'O3MPbAk0bz',
    gameTime: '2025-01-14T02:00:00.000Z',
    title: 'California vs Stanford',
    teamA: 'California',
    teamALogo: 'upload/images/team/cFqM7q75rvGOTzliayNOC',
    teamB: 'Stanford',
    teamBLogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
  },
];

const week4 = [
  {
    sessionId: 'yZd3FxBQCm',
    gameTime: '2025-01-25T00:00:00.000Z',
    title: 'Simpson vs Navy',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Simpson',
    teamALogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
    teamB: 'Navy',
    teamBLogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
  },
  {
    sessionId: 'a6Q8hnmwMz',
    gameTime: '2025-01-25T19:00:00.000Z',
    title: 'Springfield / Army / Michigan',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Springfield',
    teamALogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
    teamB: 'Army',
    teamBLogo: 'upload/images/team/wmICCck438BshXvBlPHV_',
    teamC: 'Michigan',
    teamCLogo: 'upload/images/team/OlF6zJbX8C_3TY7v_vL5S',
  },
  {
    sessionId: 'zbgG-nrEiC',
    gameTime: '2025-01-26T03:00:00.000Z',
    title: 'Stanford / Cal / Ohio State (Stanford Open)',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Stanford',
    teamALogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
    teamB: 'California',
    teamBLogo: 'upload/images/team/jfBUCDAAOrc9urkAN65iZ',
    teamC: 'Ohio State',
    teamCLogo: 'upload/images/team/1zvZAutg_lGASowMXuhnY',
  },
];

const week5 = [
  {
    sessionId: 'QGps_nLZTa',
    gameTime: '2025-02-01T18:00:00.000Z',
    title: 'Army vs Navy',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Army',
    teamALogo: 'upload/images/team/wmICCck438BshXvBlPHV_',
    teamB: 'Navy',
    teamBLogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
  },
  {
    sessionId: 'wZNp4b3nWi',
    gameTime: '2025-02-01T19:00:00.000Z',
    title: 'Greenville vs Springfield',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Greenville',
    teamALogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
    teamB: 'Springfield',
    teamBLogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
  },
  {
    sessionId: 'InHzjn8SJ9',
    gameTime: '2025-02-02T21:00:00.000Z',
    title: 'Stanford vs California',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Stanford',
    teamALogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
    teamB: 'California',
    teamBLogo: 'upload/images/team/jfBUCDAAOrc9urkAN65iZ',
  },
];

const week6 = [
  {
    sessionId: 'M4S0ftTvMQ',
    gameTime: '2025-02-08T21:00:00.000Z',
    title: 'Air Force vs California',
    tags: ['MALE'],
    teamA: 'Air Force',
    teamALogo: 'upload/images/team/AAz9Ikazg9k-YNOWvNnLa',
    teamB: 'California',
    teamBLogo: 'upload/images/team/jfBUCDAAOrc9urkAN65iZ',
  },
  {
    sessionId: '-6PrZjHzEA',
    gameTime: '2025-02-08T18:00:00.000Z',
    title: 'Army vs William & Mary',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Army',
    teamALogo: 'upload/images/team/wmICCck438BshXvBlPHV_',
    teamB: 'William & Mary',
    teamBLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
  },
  {
    sessionId: '4cPYSi41ti',
    gameTime: '2025-02-08T19:00:00.000Z',
    title: 'Michigan vs Illinois',
    tags: ['MALE'],
    teamA: 'Michigan',
    teamALogo: 'upload/images/team/OlF6zJbX8C_3TY7v_vL5S',
    teamB: 'Illinois',
    teamBLogo: 'upload/images/team/t9wWLsOPF2bpVZHqjsogl',
  },
];

const week7 = [
  {
    sessionId: 'ytGs9IqwF2',
    gameTime: '2025-02-16T18:00:00.000Z',
    title: 'Springfield vs William & Mary',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Springfield',
    teamALogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
    teamB: 'William & Mary',
    teamBLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
  },
];

const week9 = [
  {
    sessionId: 'HHNWYhYJ1e',
    gameTime: '2025-03-01T02:00:00.000Z',
    title: 'California / Oklahoma / Nebraska',
    tags: ['MALE', 'STREAMING'],
    teamA: 'California',
    teamALogo: 'upload/images/team/jfBUCDAAOrc9urkAN65iZ',
    teamB: 'Oklahoma',
    teamBLogo: 'upload/images/team/hqph8HiyQRcSV8QF_G78t',
    teamC: 'Nebraska',
    teamCLogo: 'upload/images/team/vgpxEJbZU4AIfAjSJ6-ru',
  },
  {
    sessionId: 'D2OKJS3fkQ',
    gameTime: '2025-03-01T19:00:00.000Z',
    title: 'Navy vs Springfield',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Navy',
    teamALogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
    teamB: 'Springfield',
    teamBLogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
  },
  {
    sessionId: '79vEnDONou',
    gameTime: '2025-03-02T18:00:00.000Z',
    title: 'Simpson vs William & Mary',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Simpson',
    teamALogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
    teamB: 'William & Mary',
    teamBLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
  },
];

const week10 = [
  {
    sessionId: 'gifiBznHEF',
    gameTime: '2025-03-09T18:00:00.000Z',
    title: 'Simpson vs Greenville',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Simpson',
    teamALogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
    teamB: 'Greenville',
    teamBLogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
  },
  {
    sessionId: 'iqL7BbTYYc',
    gameTime: '2025-03-09T00:00:00.000Z',
    title: "Stanford International Collegiate Open '25",
    tags: ['MALE', 'STREAMING'],
    teamA: 'Swiss',
    teamALogo: 'upload/images/team/8SfkvUnW1M4cVwiKxG1No',
    teamB: 'All Stars',
    teamBLogo: 'upload/images/team/mk2iPbZAehVQZdBexNbsH',
    teamC: 'Finland',
    teamCLogo: 'upload/images/team/L_KBiEbRYW3U-hOdR30A5',
    teamD: 'Stanford',
    teamDLogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
    teamE: 'USA',
    teamELogo: 'upload/images/team/E5hyh2M2uDgtpZLxr5-6o',
    teamF: 'California',
    teamFLogo: 'upload/images/team/jfBUCDAAOrc9urkAN65iZ',
  },
  {
    sessionId: 'h4pw0SaRKk',
    gameTime: '2025-03-08T19:00:00.000Z',
    title: 'William & Mary vs Navy',
    tags: ['MALE', 'STREAMING'],
    teamA: 'William & Mary',
    teamALogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
    teamB: 'Navy',
    teamBLogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
  },
];

const week11 = [
  {
    sessionId: 'DK-f7k0qwv',
    gameTime: '2025-03-14T22:00:00.000Z',
    title: 'Army / Simpson / Air Force',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Army',
    teamALogo: 'upload/images/team/wmICCck438BshXvBlPHV_',
    teamB: 'Simpson',
    teamBLogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
    teamC: 'Air Force',
    teamCLogo: 'upload/images/team/AAz9Ikazg9k-YNOWvNnLa',
  },
  {
    sessionId: 'qIuZ75Yokg',
    gameTime: '2025-03-15T18:00:00.000Z',
    title: 'Michigan vs Ohio State',
    tags: ['MALE'],
    teamA: 'Michigan',
    teamALogo: 'upload/images/team/OlF6zJbX8C_3TY7v_vL5S',
    teamB: 'Ohio State',
    teamBLogo: 'upload/images/team/1zvZAutg_lGASowMXuhnY',
  },
  {
    sessionId: 'sPWdvl39z3',
    gameTime: '2025-03-14T17:00:00.000Z',
    title: 'Navy vs Greenville',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Navy',
    teamALogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
    teamB: 'Greenville',
    teamBLogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
  },
  {
    sessionId: 'YoWpt39YPJ',
    gameTime: '2025-03-16T17:00:00.000Z',
    title: 'Springfield vs Simpson',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Springfield',
    teamALogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
    teamB: 'Simpson',
    teamBLogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
  },
  {
    sessionId: '8yIU2GhBAU',
    gameTime: '2025-03-15T23:00:00.000Z',
    title: 'Stanford vs California',
    tags: ['MALE', 'STREAMING'],
    teamA: 'Stanford',
    teamALogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
    teamB: 'California',
    teamBLogo: 'upload/images/team/jfBUCDAAOrc9urkAN65iZ',
  },
  {
    sessionId: 'yx7bWfur5r',
    gameTime: '2025-03-15T18:00:00.000Z',
    title: 'William & Mary / Oklahoma / Greenville',
    tags: ['MALE', 'STREAMING'],
    teamA: 'William & Mary',
    teamALogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
    teamB: 'Oklahoma',
    teamBLogo: 'upload/images/team/hqph8HiyQRcSV8QF_G78t',
    teamC: 'Greenville',
    teamCLogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
  },
];

const week13 = [
  {
    sessionId: 'Jbktbj8SaA',
    gameTime: '2025-03-29T17:00:00.000Z',
    title: "USAG Collegiate Championships '25 (Event Finals)",
    tags: ['MALE'],
    teamA: 'USAG Collegiate Event Finalists',
    teamALogo: 'upload/images/team/Na0lT0sy5rOcenjVimkba',
  },
  {
    sessionId: '7yEg0vEMSp',
    gameTime: '2025-03-28T18:00:00.000Z',
    title: "USAG Collegiate Championships '25 (Team & AA)",
    tags: ['MALE'],
    teamA: 'Simpson',
    teamALogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
    teamB: 'Air Force',
    teamBLogo: 'upload/images/team/AAz9Ikazg9k-YNOWvNnLa',
    teamC: 'Springfield',
    teamCLogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
    teamD: 'Army',
    teamDLogo: 'upload/images/team/wmICCck438BshXvBlPHV_',
    teamE: 'Navy',
    teamELogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
    teamF: 'William & Mary',
    teamFLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
  },
];

const week14 = [
  {
    sessionId: 'Nrk9aE2PW-',
    gameTime: '2025-04-05T18:00:00.000Z',
    title: "ECAC Championships '25",
    tags: ['MALE', 'STREAMING'],
    teamA: 'Navy',
    teamALogo: 'upload/images/team/QtMgsTKjUwl0sEQwnlwNT',
    teamB: 'Greenville',
    teamBLogo: 'upload/images/team/ENm2b6qi96JA3DRXNyvt9',
    teamC: 'Simpson',
    teamCLogo: 'upload/images/team/5iJiCZuUmn0V7FPYx6-Oh',
    teamD: 'Army',
    teamDLogo: 'upload/images/team/wmICCck438BshXvBlPHV_',
    teamE: 'Springfield',
    teamELogo: 'upload/images/team/uGniv3T8oFPiagZqgWXwv',
    teamF: 'William & Mary',
    teamFLogo: 'upload/images/team/MG4yA_-sLEqBckLyfy71-',
  },
  {
    sessionId: 'x8GGKWj1tC',
    gameTime: '2025-04-05T22:00:00.000Z',
    title: "MPSF Championships '25",
    tags: ['MALE'],
    teamA: 'Air Force',
    teamALogo: 'upload/images/team/AAz9Ikazg9k-YNOWvNnLa',
    teamB: 'California',
    teamBLogo: 'upload/images/team/jfBUCDAAOrc9urkAN65iZ',
    teamC: 'Oklahoma',
    teamCLogo: 'upload/images/team/hqph8HiyQRcSV8QF_G78t',
    teamD: 'Stanford',
    teamDLogo: 'upload/images/team/TxEa6Awt5quagSXQIhqtk',
  },
];

export const matches2025 = [
  week14,
  week13,
  week11,
  week10,
  week9,
  week7,
  week6,
  week5,
  week4,
  week3,
  week2,
];

// export function useMatch(key) {
//   const { data } = useQuery(GetMatch, {
//     variables: {
//       sessionKey: key,
//     },
//   });

//   return data;
// }
