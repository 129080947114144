import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { producerActions } from '../redux/_actions';
import { useQueryParams } from '../utilities/query';
import { SessionStatus } from '../models';

export function useRoundSync(syncCommand) {
  const round = useSelector((state) => state.producer.round);
  const sessionStatus = useSelector((state) => state.session.status);
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const [lastSyncRound, setLastSyncRound] = useState(null);
  const lastManualRound = useRef(null);
  const shouldSync = queryParams.get('sync') === '1';
  const [enabled, setEnabled] = useState(true && shouldSync);
  const isLive = sessionStatus === SessionStatus.LIVE;
  const isInitialLoad = useRef(true);

  useEffect(() => {
    if (
      enabled &&
      syncCommand?.round !== undefined &&
      (syncCommand.round !== lastSyncRound || isInitialLoad.current)
    ) {
      setLastSyncRound(syncCommand.round);
      // Force round change only on initial load
      if (isInitialLoad.current) {
        dispatch(producerActions.changeRound(syncCommand.round));
        isInitialLoad.current = false;
      }
      // Otherwise only change if we haven't manually changed since last sync
      else if (lastManualRound.current === null || round === lastSyncRound) {
        dispatch(producerActions.changeRound(syncCommand.round));
      }
    }
  }, [syncCommand, dispatch, round, lastSyncRound]);

  // Track manual round changes
  useEffect(() => {
    if (round !== lastSyncRound) {
      lastManualRound.current = round;
    } else {
      // Reset manual override when we match sync
      lastManualRound.current = null;
    }
  }, [round, lastSyncRound]);

  return {
    round,
    lastSyncRound,
    isManuallySet: lastManualRound.current !== null,
    setEnabled,
    enabled,
  };
}
