/*global Rollbar */
import { Auth } from 'aws-amplify';
import { createAuthLink } from 'aws-appsync-auth-link';
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

function getAuthTypeConfig(config) {
  const authTypeConfig = {
    [GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS]: {
      jwtToken: async () =>
        (await Auth.currentSession()).getAccessToken().getJwtToken(),
    },
    [GRAPHQL_AUTH_MODE.API_KEY]: {
      apiKey: config.aws_appsync_apiKey,
    },
    [GRAPHQL_AUTH_MODE.AWS_IAM]: {
      credentials: () => Auth.currentCredentials(),
    },
  };

  return authTypeConfig[config.aws_appsync_authenticationType] || {};
}

export function cacheIdForNode(node) {
  if (!node.id) {
    throw new Error(
      'Cannot generate Apollo cache ID for node without id field'
    );
  }
  if (!node.__typename) {
    throw new Error(
      'Cannot generate Apollo cache ID for node without __typename field'
    );
  }

  return `${node.__typename}:${node.id}`;
}

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      Rollbar.error(message);
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export function createApolloClient(config) {
  const url = config.aws_appsync_graphqlEndpoint;
  const region = config.aws_appsync_region;

  const auth = {
    type: config.aws_appsync_authenticationType,
    ...getAuthTypeConfig(config),
  };

  const link = ApolloLink.from([
    errorLink,
    createAuthLink({ url, region, auth }),
    createHttpLink({ uri: url }),
  ]);

  // This 'fields' object is how we tell the Apollo cache how to resolve to the correct
  // node type when Amplify's node-getters are called. E.g. getLineup(id: 1234) should
  // resolve to Lineup-1234, if it's available in cache. The default Apollo behavior relies
  // on the getter and the node typename being equal
  const fields = {};
  [
    'Stream',
    'Session',
    'Lineup',
    'Routine',
    'Clip',
    'Score',
    'Inquiry',
    'Rotation',
    'Stage',
    'Squad',
    'SquadMember',
    'AthleteContext',
    'Roster',
    'Athlete',
    'Overlay',
    'Penalty',
    'Judge',
    'SessionJudgeAssignment',
    'SessionJudge',
    'StageJudge',
  ].forEach((__typename) => {
    fields[`get${__typename}`] = (_, { args, toReference }) =>
      toReference({ __typename, id: args.id });
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields,
      },
      ModelSessionJudgeAssignmentConnection: {
        // This tells Apollo not to try to merge connection objects automatically
        keyFields: false,
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  });
  return new ApolloClient({
    link,
    cache,
    connectToDevTools: process.env.NODE_ENV !== 'production',
  });
}
