import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { Alert, Container, Button, Modal, Table, Form } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import TableComponent from '../helpers/tablecomponent';
import UpdateJudge from '../../apollo/mutations/UpdateJudge.graphql';
import { SearchInput, addBold, noResults } from '../helpers/search';
import { Loading } from '../helpers/loading';
import SearchJudgesWithAssignments from '../../apollo/queries/SearchJudgesWithAssignments.graphql';
import './dashboard.css';

function JudgeDashboard() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const containerRef = useRef(null);

  const { loading, error, data, fetchMore } = useQuery(
    SearchJudgesWithAssignments,
    {
      variables: {
        filter: searchTerm
          ? {
              or: [{ name: { wildcard: `*${searchTerm}*` } }],
            }
          : null,
        limit: 100,
        nextToken: null,
      },
    }
  );

  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setHasMore(!!data?.searchJudges?.nextToken);
  }, [data?.searchJudges?.nextToken]);

  const loadMore = async () => {
    if (!hasMore || !data?.searchJudges?.nextToken || isLoadingMore) return;

    setIsLoadingMore(true);
    try {
      await fetchMore({
        variables: {
          filter: searchTerm
            ? {
                or: [{ name: { wildcard: `*${searchTerm}*` } }],
              }
            : null,
          limit: 100,
          nextToken: data.searchJudges.nextToken,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          // Check if we've reached the end
          setHasMore(!!fetchMoreResult.searchJudges.nextToken);

          return {
            searchJudges: {
              ...fetchMoreResult.searchJudges,
              items: [
                ...prev.searchJudges.items,
                ...fetchMoreResult.searchJudges.items,
              ],
              nextToken: fetchMoreResult.searchJudges.nextToken,
            },
          };
        },
      });
    } catch (error) {
      console.error('Error loading more judges:', error);
      setHasMore(false);
    }
    setIsLoadingMore(false);
  };

  const isSearch = searchTerm !== '';

  const headers = useMemo(
    () => [
      { Header: '#', accessor: 'order' },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value, row }) => {
          const [isEditing, setIsEditing] = useState(false);
          const [editValue, setEditValue] = useState(value);
          const inputRef = useRef(null);

          useEffect(() => {
            if (isEditing && inputRef.current) {
              inputRef.current.focus();
            }
          }, [isEditing]);

          const [updateJudge] = useMutation(UpdateJudge);

          const handleSave = async () => {
            try {
              await updateJudge({
                variables: {
                  input: {
                    id: row.original.id,
                    name: editValue,
                    _version: row.original._version
                  }
                }
              });
              setIsEditing(false);
            } catch (error) {
              console.error('Error updating judge:', error);
              alert('Failed to update judge name');
            }
          };

          const handleCancel = () => {
            setEditValue(value);
            setIsEditing(false);
          };

          const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
              handleSave();
            } else if (e.key === 'Escape') {
              handleCancel();
            }
          };

          if (isEditing) {
            return (
              <div className="d-flex align-items-center">
                <Form.Control
                  ref={inputRef}
                  type="text"
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                  size="sm"
                  className="me-2"
                  style={{ minWidth: '200px' }}
                />
                <Button
                  variant="success"
                  size="sm"
                  onClick={handleSave}
                  className="ml-2"
                >
                  Save
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={handleCancel}
                  className="ml-2"
                >
                  Cancel
                </Button>
              </div>
            );
          }

          return (
            <div
              className="cursor-pointer p-2"
              onClick={() => setIsEditing(true)}
              role="button"
              title="Click to edit"
            >
              {addBold(value, searchTerm)}
            </div>
          );
        },
      },
      { Header: 'ID', accessor: 'id' },
      {
        Header: "Women's Certifications",
        accessor: 'womens',
        Cell: ({ row }) => {
          const certs = [];
          if (row.original.womensBrevet) certs.push('Brevet');
          if (row.original.womensNational) certs.push('National');
          if (row.original.womensLevel10) certs.push('Level 10');
          return certs.join(', ') || '-';
        },
      },
      {
        Header: "Men's Certifications",
        accessor: 'mens',
        Cell: ({ row }) => {
          const certs = [];
          if (row.original.mensBrevet) certs.push('Brevet');
          if (row.original.mensOptional) certs.push('Optional');
          if (row.original.mensCompulsory) certs.push('Compulsory');
          return certs.join(', ') || '-';
        },
      },
      { Header: 'Created', accessor: 'createdAt' },
      { Header: 'Updated', accessor: 'updatedAt' },
      {
        Header: 'Assignments',
        accessor: 'assignments',
        Cell: ({ row }) => {
          const [showModal, setShowModal] = useState(false);
          const assignments =
            row.original.sessionAssignments?.items
              ?.filter((item) => !item._deleted)
              ?.map((item) => ({
                name: item.session?.name,
                date: new Date(item.session?.startAt).toLocaleDateString(),
                status: item.session?.status,
                sessionKey: item.session?.sessionKey
              })) || [];

          return (
            <>
              <Button
                variant="link"
                onClick={() => setShowModal(true)}
                className="p-0"
              >
                {assignments.length || 0} Assignments
              </Button>

              <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size="lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Assignments for {row.original.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {assignments.length > 0 ? (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Session</th>
                          <th>Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...assignments]
                          .sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((a, i) => (
                            <tr key={i}>
                              <td>
                                <a 
                                  href={`/session?s=${a.sessionKey}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {a.name}
                                </a>
                              </td>
                              <td>{a.date}</td>
                              <td>{a.status}</td>
                            </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No assignments found</p>
                  )}
                </Modal.Body>
              </Modal>
            </>
          );
        },
      },
      {
        Header: 'Errors',
        accessor: 'errors',
        Cell: ({ row }) => {
          const errors = [];
          if (!row.original.id) errors.push('Missing ID');
          if (!row.original.name) errors.push('Missing Name');
          if (!row.original.updatedAt) errors.push('Missing Update Time');
          if (typeof row.original.womensBrevet !== 'boolean')
            errors.push("Invalid Women's Brevet");
          if (typeof row.original.womensNational !== 'boolean')
            errors.push("Invalid Women's National");
          if (typeof row.original.womensLevel10 !== 'boolean')
            errors.push("Invalid Women's Level 10");
          if (typeof row.original.mensBrevet !== 'boolean')
            errors.push("Invalid Men's Brevet");
          if (typeof row.original.mensOptional !== 'boolean')
            errors.push("Invalid Men's Optional");
          if (typeof row.original.mensCompulsory !== 'boolean')
            errors.push("Invalid Men's Compulsory");
          return errors.length ? errors.join(', ') : '-';
        },
      },
    ],
    [searchTerm]
  );

  const tableData = useMemo(() => {
    if (!data?.searchJudges?.items) return [];
    return data.searchJudges.items
      .filter(
        (judge) =>
          judge &&
          judge.id &&
          judge.name?.toString() &&
          judge.updatedAt &&
          typeof judge.womensBrevet === 'boolean' &&
          typeof judge.womensNational === 'boolean' &&
          typeof judge.womensLevel10 === 'boolean' &&
          typeof judge.mensBrevet === 'boolean' &&
          typeof judge.mensOptional === 'boolean' &&
          typeof judge.mensCompulsory === 'boolean'
      ) // Filter out null items
      .map((judge, i) => ({
        order: i + 1,
        id: judge.id,
        name: judge.name,
        womensBrevet: judge.womensBrevet || false,
        womensNational: judge.womensNational || false,
        womensLevel10: judge.womensLevel10 || false,
        mensBrevet: judge.mensBrevet || false,
        mensOptional: judge.mensOptional || false,
        mensCompulsory: judge.mensCompulsory || false,
        createdAt: new Date(judge.createdAt).toLocaleString(),
        updatedAt: new Date(judge.updatedAt).toLocaleString(),
        sessionAssignments: judge.sessionAssignments,
        _version: judge._version,
      }));
  }, [data]);

  const handleSearch = (query) => {
    setSearchTerm(query || '');
  };

  return (
    <Container fluid className="dashboard-container">
      <div className="dashboard-header">
        <div className="d-flex align-items-center mb-3">
          <h2 className="mb-0 me-3">Judge Dashboard</h2>
          <SearchInput searchTerm={searchTerm} search={handleSearch} />
        </div>
      </div>

      {error && (
        <Alert variant="danger" className="dashboard-alert">
          Error loading judges: {error.message}
        </Alert>
      )}

      <div
        className="dashboard-content"
        ref={containerRef}
        onScroll={useCallback(
          (e) => {
            const bottom =
              e.target.scrollHeight - e.target.scrollTop <=
              e.target.clientHeight + 100;
            if (bottom && !isLoadingMore && hasMore) {
              loadMore();
            }
          },
          [isLoadingMore, loadMore]
        )}
      >
        {loading ? (
          <Loading />
        ) : tableData.length === 0 && !isSearch ? (
          noResults('judges')
        ) : (
          <>
            <TableComponent
              columns={headers}
              data={tableData}
              initialState={{ sortBy: [{ id: 'name', desc: false }] }}
              searchTerm={searchTerm}
              textSort={true}
              showFooter={true}
              filterColumns={['name']}
            />
            {isLoadingMore && (
              <div className="text-center py-3">
                <Loading size="sm" />
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
}

export default JudgeDashboard;
