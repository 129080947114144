import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import GetOverlays from '../apollo/queries/GetOverlays.graphql';

export function useOverlaySync(sessionId, options = {}) {
  const [overlaySync, setOverlaySync] = useState(null);
  const [overlayIndex, setOverlayIndex] = useState(options.initialOverlayIndex || 0);
  const [round, setRound] = useState(options?.initialRound ?? 1);
  const [showScorebug, setShowScorebug] = useState(options.initialShowScorebug ?? true);
  const [showLogo, setShowLogo] = useState(options.initialShowLogo ?? true);
  const [syncCommand, setSyncCommand] = useState(null);
  const [initialized, setInitialized] = useState(false);
  
  const { data: overlayData } = useQuery(GetOverlays, {
    variables: { sessionId },
    skip: !sessionId,
  });

  // Initialize from overlay data
  useEffect(() => {
    if (!initialized && overlayData?.SessionOverlays?.items?.length > 0) {
      const lastOverlay = overlayData.SessionOverlays.items
        .slice()
        .sort((a, b) => new Date(b.displayAt) - new Date(a.displayAt))[0];

      if (lastOverlay?.command) {
        const parsedCommand = JSON.parse(lastOverlay.command);
        setSyncCommand(parsedCommand);
        if (parsedCommand.round !== undefined) {
          setRound(parsedCommand.round);
        }
      }
      setInitialized(true);
    } else if (!initialized) {
      setInitialized(true);
    }
  }, [overlayData, initialized]);

  // Handle new overlay sync
  useEffect(() => {
    const oSync =
      overlayData &&
      overlayData.SessionOverlays.items
        .slice()
        .sort((a, b) => new Date(b.displayAt) - new Date(a.displayAt));

    if (!oSync || !oSync.length) {
      return;
    }

    setOverlaySync(oSync[0]);

    if (oSync[0]?.command) {
      const parsedCommand = JSON.parse(oSync[0].command);
      setSyncCommand(parsedCommand);
      if (parsedCommand.round !== undefined) {
        setRound(parsedCommand.round);
      }
    }
  }, [overlayData]);

  const getLatestCommand = () => {
    return overlaySync?.command ? JSON.parse(overlaySync.command) : null;
  };

  const generateCommand = (customProps = {}) => {
    return {
      overlayIndex,
      round,
      showScorebug,
      showLogo,
      ...customProps,
    };
  };

  return {
    // State
    overlaySync,
    overlayIndex,
    round,
    showScorebug,
    showLogo,
    syncCommand,
    initialized,
    
    // Setters
    setOverlayIndex,
    setRound,
    setShowScorebug,
    setShowLogo,
    
    // Helpers
    latestCommand: getLatestCommand(),
    generateCommand,
    overlayData
  };
}
