import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { downloadIcon, plusIcon, screenshotIcon } from '../helpers/icons';
import { Alert, Form, Button, ButtonGroup, Container, Modal } from 'react-bootstrap';
import { useApolloClient } from '@apollo/client';
import { createOverlay, useErrorHandler } from '../../apollo/helpers';
import SessionDetail from './sessiondetail';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './dashboard.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions, adminActions } from '../../redux/_actions';
import TableComponent from '../helpers/tablecomponent';
import { dateTime } from '../../utilities/conversions';
import { accessType, sessionStatus } from '../../redux/_constants';
import { Loading, ScrollLoading } from '../helpers/loading';
import { SearchInput, addBold, noResults } from '../helpers/search';
import StreamCreate from './streamcreate';

function LiveDashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [isScrollLoading, setIsScrollLoading] = useState(false);
  const alert = useSelector((state) => state.alert);
  const sessions = useSelector((state) => state.admin.sessions);
  const sessionTeams = useSelector((state) => state.admin.sessionTeams);
  const sessionJudges = useSelector((state) => state.admin.sessionJudges);
  const sessionStreams = useSelector((state) => state.admin.streams);
  const searchQuery = useSelector(
    (state) => state.admin.sessions.tokens.searchQuery
  );

  const [searchTerm, setSearchTerm] = useState(searchQuery || '');
  const [tempSearchTerm, setTempSearchTerm] = useState(searchQuery || '');
  const [filters, setFilters] = useState({
    gender: null,
    status: 'LIVE',  // Default to showing LIVE sessions
    start: null,
    demo: 'hide',  // Default to hiding demos
    startDate: null,
    endDate: null,
  });
  const [selectedSession, setSelectedSession] = useState(null);
  const [showStreamCreate, setShowStreamCreate] = useState(null);
  const apolloClient = useApolloClient();
  const userId = useSelector((state) => state.user.profile?.id);
  const handleCreateOverlayErrors = useErrorHandler('There was a problem sending the overlay');

  const dispatch = useDispatch();
  const numSessions = sessions?.allIds?.length || 0;
  const isSearch = searchTerm !== '';
  const [tableData, setData] = useState([]);

  useEffect(() => {
    dispatch(alertActions.clear());
    if (numSessions === 0) {
      setIsLoading(true);
      console.log('Fetching sessions');
      dispatch(adminActions.getSessions());
    }
  }, [dispatch, numSessions]);

  useEffect(() => {
    setIsLoading(false);
    setIsScrollLoading(false);
    setSearchTerm(tempSearchTerm);
  }, [sessions, tempSearchTerm]);

  const headers = useMemo(() => [
    { Header: '#', accessor: 'order' },
    {
      Header: 'Name',
      accessor: 'name',
      maxWidth: 50,
      Cell: (r) => addBold(r.cell.value, searchTerm),
    },
    { Header: 'Type', accessor: 'type' },
    { Header: 'Gender', accessor: 'gender' },
    {
      Header: 'Key',
      accessor: 'key',
      Cell: ({ row }) => (
        <Link
          to={{
            pathname: '/session',
            search: `?s=${row.values.key}`,
            state: { fromAdmin: true },
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.values.key}
        </Link>
      ),
    },
    {
      Header: 'Start',
      accessor: 'start',
      Cell: ({ row }) => dateTime(row.values.start),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (r) => {
        const { row, column, cell, updateMyData } = r;
        return (
          <Form.Control
            as="select"
            label="Status"
            value={cell.value}
            disabled
          >
            {Object.keys(sessionStatus).map((statusType) => (
              <option key={statusType} value={statusType}>
                {statusType}
              </option>
            ))}
          </Form.Control>
        );
      },
    },
    {
      Header: 'Rotations',
      accessor: 'rotations',
      Cell: ({ row }) => {
        const numRotations = row.values.rotations;
        return (
          <div className="d-flex align-items-center gap-2">
            <ButtonGroup size="sm">
              {[...Array(numRotations)].map((_, index) => (
                <Button
                  key={index}
                  variant={tableData[row.index]?.selectedRotation === index + 1 ? "secondary" : "outline-secondary"}
                  onClick={(e) => {
                    e.stopPropagation();
                    const newData = [...tableData];
                    if (!newData[row.index]) {
                      newData[row.index] = {};
                    }
                    newData[row.index] = {
                      ...newData[row.index],
                      ...row.original,
                      selectedRotation: index + 1
                    };
                    setData(newData);
                  }}
                >
                  {index + 1}
                </Button>
              ))}
            </ButtonGroup>
            <Button
              variant="light"
              size="sm"
              className="mx-1"
              onClick={(e) => {
                e.stopPropagation();
                const input = {
                  command: JSON.stringify({
                    msg: "Let's go",
                    userId,
                    round: tableData[row.index]?.selectedRotation || 1,
                    id: Date.now()
                  }),
                  sessionId: row.original.id,
                  displayAt: new Date(),
                };
                
                createOverlay(apolloClient, input)
                  .then(() => {
                    dispatch(
                      alertActions.success(
                        `Synced session ${row.original.name} to rotation ${tableData[row.index]?.selectedRotation || 1}`
                      )
                    );
                  })
                  .catch((e) => {
                    console.log(e);
                    handleCreateOverlayErrors();
                  });
              }}
            >
              Sync
            </Button>
          </div>
        );
      },
    },
    {
      Header: 'Streams',
      accessor: 'streams',
      Cell: ({ row }) => {
        const streams = Array.isArray(row.original.streams)
          ? row.original.streams
          : [];
        return (
          <div className="streams-cell d-flex flex-column">
            <div className="d-flex flex-wrap align-items-center gap-1">
              {streams.map((stream, index) => (
                <div key={index} style={{marginRight: '0.4rem'}}>{stream || 'N/A'}</div>
              ))}
              <Button
                variant="light"
                className="stream-add-button"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowStreamCreate(row.original.id);
                }}
              >
                {plusIcon}
              </Button>
            </div>
          </div>
        );
      },
    },
  ], [searchTerm, sessions, dispatch, tableData]);
  
  useEffect(() => {
    setData(data);
  }, [sessions, sessionStreams, filters]);
  
  const data = useMemo(() => {
    let filteredData = sessions.allIds.map((id, i) => {
      // Initialize selectedRotation in the data
      const {
        name,
        type,
        gender,
        sessionKey,
        startAt,
        access,
        status,
        streams,
        rotations,
      } = sessions.byId[id];

      return {
        order: i + 1,
        name: name,
        type: type,
        gender: gender,
        key: sessionKey,
        start: startAt,
        status: status,
        id: id,
        streams:
          streams?.items?.map((stream) => {
            const streamId = typeof stream === 'string' ? stream : stream.id;
            return sessionStreams.byId[streamId]?.title;
          }) || [],
        rotations: rotations?.items?.filter(r => !r._deleted)?.length || 0,
        selectedRotation: null,
      };
    });

    if (filters.gender) {
      filteredData = filteredData.filter(
        (row) => row.gender === filters.gender
      );
    }
    if (filters.status) {
      filteredData = filteredData.filter(
        (row) => row.status === filters.status
      );
    }
    if (filters.start === 'today') {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      filteredData = filteredData.filter((row) => {
        const startDate = new Date(row.start);
        return startDate >= today && startDate < tomorrow;
      });
    }
    if (filters.demo === 'hide') {
      filteredData = filteredData.filter((row) => {
        return !row.name.toLowerCase().includes('demo');
      });
    }
    if (filters.startDate) {
      filteredData = filteredData.filter((row) => {
        const sessionDate = new Date(row.start);
        const startDate = new Date(filters.startDate);
        startDate.setHours(0, 0, 0, 0);

        if (filters.endDate) {
          const endDate = new Date(filters.endDate);
          endDate.setHours(23, 59, 59, 999);
          return sessionDate >= startDate && sessionDate <= endDate;
        }

        return sessionDate.toDateString() === startDate.toDateString();
      });
    }

    return filteredData;
  }, [sessions, sessionStreams, filters]);

  const FilterPill = ({ label, value, filterKey, className = '' }) => (
    <Button
      variant={filters[filterKey] === value ? 'secondary' : 'outline-secondary'}
      className={`rounded-pill py-0 px-2 mx-1 ${className}`}
      size="sm"
      onClick={() =>
        setFilters((prev) => ({
          ...prev,
          [filterKey]: prev[filterKey] === value ? null : value,
        }))
      }
    >
      {label}
    </Button>
  );

  const handleSearch = (query) => {
    setTempSearchTerm(query);
    if (query && query !== '') {
      dispatch(
        adminActions.getSessions({ searchQuery: query, nextToken: null })
      );
    } else {
      setSearchTerm(query);
    }
  };

  return (
    <Container fluid className="dashboard-container">
      <div className="dashboard-header">
        <div className="d-flex align-items-center mb-3">
          <h2 className="mb-0 me-3">Live Dashboard</h2>
          <SearchInput searchTerm={searchTerm} search={handleSearch} />
        </div>
        <div className="filter-container">
          <FilterPill label="Male" value="MALE" filterKey="gender" />
          <FilterPill label="Female" value="FEMALE" filterKey="gender" />
          <FilterPill label="Hide Demos" value="hide" filterKey="demo" />
          <FilterPill label="Today" value="today" filterKey="start" />
          <DatePicker
            selectsRange={true}
            startDate={filters.startDate}
            endDate={filters.endDate}
            onChange={(update) => {
              const [start, end] = update;
              setFilters((prev) => ({
                ...prev,
                startDate: start,
                endDate: end,
              }));
            }}
            dateFormat="MM/dd/yyyy"
            className="date-picker-pill"
            placeholderText="Date Range"
            isClearable
            customInput={
              <Button
                size="sm"
                variant={filters.startDate ? 'secondary' : 'outline-secondary'}
                className="rounded-pill py-0 px-3"
              >
                {filters.startDate
                  ? filters.endDate
                    ? `${new Date(
                        filters.startDate
                      ).toLocaleDateString()} - ${new Date(
                        filters.endDate
                      ).toLocaleDateString()}`
                    : new Date(filters.startDate).toLocaleDateString()
                  : 'Date Range'}
              </Button>
            }
          />
        </div>
      </div>

      {!alert.clear && alert.message && (
        <Alert
          dismissible
          onClose={() => dispatch(alertActions.clear())}
          variant={alert.type === 'alert-danger' ? 'danger' : 'success'}
          className="dashboard-alert"
        >
          {alert.message}
        </Alert>
      )}

      {/* Add auto-dismiss effect for alerts */}
      {useEffect(() => {
        if (alert.message) {
          const timer = setTimeout(() => {
            dispatch(alertActions.clear());
          }, 5000); // 5 seconds
          return () => clearTimeout(timer);
        }
      }, [alert.message, dispatch])}

      <div className="dashboard-content">
        {isLoading || numSessions === 0 ? (
          <Loading />
        ) : data.length === 0 && !isSearch ? (
          noResults('live sessions')
        ) : (
          <TableComponent
            columns={headers}
            data={data}
            initialState={{ 
              sortBy: [{ id: 'start', desc: false }] 
            }}
            searchTerm={searchTerm}
            textSort={true}
            showFooter={true}
            filterColumns={['name']}
            onClick={(e, row) => {
              if (
                e.target.tagName === 'BUTTON' ||
                e.target.tagName === 'SELECT' ||
                e.target.closest('button') ||
                e.target.closest('select')
              ) {
                return;
              }
              setSelectedSession(row.id);
            }}
            updateMyData={(rowIndex, columnId, value) => {
              setData(old =>
                old.map((row, index) => {
                  if (index === rowIndex) {
                    return {
                      ...row,
                      [columnId]: value
                    }
                  }
                  return row;
                })
              );
            }}
          />
        )}
        {isScrollLoading && <ScrollLoading />}
      </div>

      <Modal
        show={selectedSession !== null}
        onHide={() => setSelectedSession(null)}
        size="xl"
        centered
        dialogClassName="session-modal"
      >
        {selectedSession && (
          <SessionDetail
            sessionId={selectedSession}
            sessionKey={sessions.byId[selectedSession]?.sessionKey}
            back={() => setSelectedSession(null)}
          />
        )}
      </Modal>

      <StreamCreate
        show={showStreamCreate !== null}
        onHide={() => setShowStreamCreate(null)}
        sessionId={showStreamCreate}
      />
    </Container>
  );
}

export default LiveDashboard;
