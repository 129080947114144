import {
  convertMillipointsToDisplay,
  newestScoreOfType,
  TEN,
  scoreEAverage,
} from './scoring';
import { GenderType, RoutineStatus } from '../models';
import { evaluatorConfigs } from '../redux/_constants';
import { displayName } from './conversions';

// For Clipboard work
export const copyToClipboard = (data) => {
  if (!navigator.clipboard) {
    // Clipboard API not available
    let textField = document.createElement('textarea');
    textField.textContent = data;
    textField.innerHTML = textField.innerHTML.replace(/\n/g, '\n');
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  } else {
    navigator.clipboard.writeText(data);
  }

  console.log('Data copied to clipboard');
};

export const exportAllDataApollo = (session, admin = false) => {
  // needs to be based on actual data D/E setup
  const evalCfg = evaluatorConfigs[session?.judgePanel ?? 'DEFAULT'];
  const adminData = ['Clip Start', 'Clip End', 'Start Time', 'End Time'];
  const info = [
    [
      'Round',
      'Order',
      'Name',
      'Team',
      'Event',
      'Score',
      'Difficulty',
      'Execution',
      'Neutral Deduction',
      'Stick Bonus',
      'Inquiry',
      'Edited',
      'Date',
      'Event',
      ...(admin ? adminData : []),
      ...(evalCfg?.dPanel ? evalCfg?.dPanel?.map((d) => d.type) : []),
      ...(evalCfg?.ePanel ? evalCfg?.ePanel?.map((e) => e.type) : []),
      ...(evalCfg?.svPanel ? evalCfg?.svPanel?.map((sv) => sv.type) : []),
      ...(evalCfg?.jPanel ? evalCfg?.jPanel?.map((j) => j.type) : []),
    ],
  ];

  const rounds = [];

  const teamsByAthleteId = session.rosters.items.reduce(
    (acc, sessionRoster) => {
      if (!sessionRoster._deleted) {
        acc = {
          ...acc,
          ...sessionRoster.roster.athleteContexts.items.reduce(
            (innerAcc, rosterAthleteContext) => {
              if (!rosterAthleteContext._deleted) {
                innerAcc[rosterAthleteContext.athleteContext.athleteId] =
                  sessionRoster.roster.team;
              }
              return innerAcc;
            },
            {}
          ),
        };
      }
      return acc;
    },
    {}
  );

  // sort lineups by "order"
  const lineups = [...session.lineups.items];
  lineups.sort((a, b) => a.order - b.order);

  lineups.forEach((lineup) => {
    const lineupData = JSON.parse(lineup.lineupData);
    lineup.routines.items.forEach((routine) => {
      const rotation = routine.rotation;

      if (!rounds[rotation]) {
        rounds[rotation] = [];
      }
      if (routine.status !== RoutineStatus.COMPLETE) {
        return;
      }

      // Handle clip data
      const topClip = routine.clips.items.length
        ? routine.clips.items[0]
        : null;
      const clipStart = topClip?.seekStartSeconds ?? '';
      const clipEnd = topClip?.seekEndSeconds ?? '';
      const startTime = topClip?.startTime ?? '';
      const endTime = topClip?.endTime ?? '';

      // Handle score data
      const scores = routine.scores.items.filter(
        (score) =>
          new Date(score.createdAt).getTime() <
          new Date(routine.updatedAt).getTime()
      );
      const scoreD = newestScoreOfType(scores, evalCfg?.dPanel[0]?.type)?.value;
      const scoreND = newestScoreOfType(scores, 'ND')?.value || null;
      const scoreSB = newestScoreOfType(scores, 'SB')?.value || null;
      const scoreEAvg = scoreEAverage(evalCfg, scores);
      const noTruncation = evalCfg.startValue && evalCfg.ePanel.length === 6; // special case for NCAAW6 x.xxxx
      const totalScorePrecision = noTruncation ? 4 : 3;
      const athleteId = lineupData[rotation]?.[routine.order]?.athleteId;

      if (!athleteId) {
        console.error('No lineup data found for routine', routine);
        return;
      }

      const rosterTeam = teamsByAthleteId[athleteId];
      const teamName =
        (rosterTeam
          ? displayName(rosterTeam.name, rosterTeam.altNames)
          : displayName(lineup.team.name, lineup.team.altNames)) +
        (session.name !== lineup.title ? `: ${lineup.title}` : '');

      rounds[rotation].push([
        rotation,
        routine.order + 1,
        lineupData[rotation][routine.order].name,
        teamName,
        session.gender === GenderType.FEMALE
          ? wGymAbbv(rotation) // need to actually read the apparatus name not just rotation
          : mGymAbbv(rotation),
        convertMillipointsToDisplay(routine.score, totalScorePrecision),
        scoreD ? convertMillipointsToDisplay(scoreD) : '',
        scoreEAvg
          ? convertMillipointsToDisplay(scoreEAvg, totalScorePrecision)
          : '',
        scoreND ? convertMillipointsToDisplay(scoreND) : '',
        scoreSB ? convertMillipointsToDisplay(scoreSB) : '',
        (routine?.inquiries?.items?.length > 0 && 'Y') || '',
        (routine?.isEdited && 'Y') || '', // for changes
        new Date(session.startAt).toLocaleDateString(),
        session.name,
        ...(admin ? [clipStart, clipEnd, startTime, endTime] : []),
        ...(evalCfg?.dPanel
          ? evalCfg?.dPanel.map((d) => {
              const score = newestScoreOfType(scores, d.type)?.value;
              return score === undefined
                ? ''
                : convertMillipointsToDisplay(score);
            })
          : []),
        ...(evalCfg?.ePanel
          ? evalCfg?.ePanel.map((e) => {
              const score = newestScoreOfType(scores, e.type)?.value;
              return score === undefined
                ? ''
                : convertMillipointsToDisplay(score);
            })
          : []),
        ...(evalCfg?.svPanel
          ? evalCfg?.svPanel.map((sv) => {
              const score = newestScoreOfType(scores, sv.type)?.value;
              return score === undefined
                ? ''
                : convertMillipointsToDisplay(score);
            })
          : []),
        ...(evalCfg?.jPanel
          ? evalCfg?.jPanel.map((j) => {
              const score = newestScoreOfType(scores, j.type)?.value;
              return score === undefined
                ? ''
                : convertMillipointsToDisplay(score);
            })
          : []),
      ]);
    });
  });
  rounds.forEach((round) => round.forEach((routine) => info.push(routine)));
  return info;
};

export const exportAllData = (session) => {
  const info = [
    [
      'Round',
      'Order',
      'Name',
      'Team',
      'Event',
      'Score',
      'Difficulty',
      'Execution',
      'Neutral Deduction',
      'Stick Bonus',
      'Inquiry',
      'Date',
      'Event',
      'D',
      'E1',
      'E2',
    ],
  ];
  const rounds = [];

  //console.log(session.lineups.items)

  session.lineups.items.forEach((lineup) => {
    const lineupData = JSON.parse(lineup.lineupData);
    lineup.routines.items.forEach((routine) => {
      if (!rounds[routine.rotation]) {
        rounds[routine.rotation] = [];
      }
      if (routine.status !== RoutineStatus.COMPLETE) {
        return;
      }

      const order = 2 * routine.order + lineup.order + 1;
      const scores = routine.scores.items;

      const scoreD = newestScoreOfType(scores, 'D').value;
      const deductionE1 = newestScoreOfType(scores, 'E1').value;
      const scoreE1 = TEN - deductionE1;
      const deductionE2 = newestScoreOfType(scores, 'E2').value;
      const scoreE2 = TEN - deductionE2;
      const scoreND = newestScoreOfType(scores, 'ND')?.value || 0;
      const scoreSB = newestScoreOfType(scores, 'SB')?.value || 0;

      const scoreEAverage = (scoreE1 + scoreE2) / 2;

      rounds[routine.rotation][order] = [
        routine.rotation,
        order,
        lineupData[routine.rotation][routine.order].name,
        lineup.team.name,
        session.gender === GenderType.FEMALE
          ? wGymAbbv(routine.rotation)
          : mGymAbbv(routine.rotation),
        convertMillipointsToDisplay(routine.score),
        convertMillipointsToDisplay(scoreD),
        convertMillipointsToDisplay(scoreEAverage),
        convertMillipointsToDisplay(scoreND),
        convertMillipointsToDisplay(scoreSB),
        (routine.isEdited && 'Y') || '',
        new Date(session.startAt).toLocaleDateString(),
        session.name,
        convertMillipointsToDisplay(scoreD),
        convertMillipointsToDisplay(deductionE1),
        convertMillipointsToDisplay(deductionE2),
      ];
    });
  });
  rounds.forEach((round) => round.forEach((routine) => info.push(routine)));
  return info;
};

// Allows for lazy input of single numeric characters, i.e. 5 -> 5.0
export const exportData = (
  teamA,
  teamB,
  round,
  header,
  gender,
  eventName,
  eventDate,
  showHeader = true
) => {
  const replacer = (key, value) => (value === null ? '' : value);
  const headerMapper = (key, team, home, index) => {
    const athlete = team.lineup[index];
    const evaluation = athlete.evaluation;

    switch (key) {
      case 'Round':
        return round;
      case 'Order':
        return home ? 2 * index + 1 : 2 * index + 2;
      case 'Name':
        return athlete.name;
      case 'ID':
        return athlete.athleteId;
      case 'Team':
        return team.name;
      case 'Score':
        return evaluation ? evaluation.score : null;
      case 'Difficulty':
        return evaluation ? evaluation.dScore : null;
      case 'Execution':
        return evaluation ? evaluation.eScore : null;
      case 'Neutral Deduction':
        return evaluation ? evaluation.ND : null;
      case 'Stick Bonus':
        return evaluation ? evaluation.SB : null;
      case 'Start Time':
        return athlete.startTime;
      case 'End Time':
        return athlete.endTime;
      case 'Rotation':
        if (gender === 'MALE') {
          return mGymAbbv(round);
        }
        if (gender === 'FEMALE') {
          return wGymAbbv(round);
        }
        return null;
      case 'Date':
        const date = eventDate ? new Date(eventDate) : new Date();
        return date.toLocaleDateString();
      case 'Event':
        return eventName ?? 'Virtius Event';
      case 'Inquiry':
        return evaluation
          ? evaluation.evalPanel.flags.inquiry
            ? 'Y'
            : null
          : null;
      default:
        // handle D and E subscores
        if (key[0] === 'D' || key[0] === 'E') {
          return evaluation &&
            evaluation.evalPanel.subScores.hasOwnProperty(key)
            ? evaluation.evalPanel.subScores[key][
                evaluation.evalPanel.subScores[key].length - 1
              ].score
            : null;
        } else {
          return null;
        }
    }
  };

  const teamAData =
    teamA.lineup &&
    teamA.lineup.length &&
    teamA.lineup
      .map((row, index) => {
        return header
          .map((key) => {
            return JSON.stringify(
              headerMapper(key, teamA, true, index),
              replacer
            );
          })
          .join('\t');
      })
      .join('\n');

  const teamBData =
    teamB.lineup &&
    teamB.lineup.length &&
    teamB.lineup
      .map((row, index) => {
        return header
          .map((key) => {
            return JSON.stringify(
              headerMapper(key, teamB, false, index),
              replacer
            );
          })
          .join('\t');
      })
      .join('\n');

  const headerData = showHeader ? header.map((key) => key).join('\t') : null;
  const data = teamAData && teamBData && [teamAData, teamBData].join('\n');
  const dataPlusHeader = headerData
    ? data
      ? [headerData, data].join('\n')
      : headerData
    : data;

  return dataPlusHeader;
};

export const mGymAbbv = (val) => {
  const abbv = {
    1: 'FX',
    2: 'PH',
    3: 'SR',
    4: 'VT',
    5: 'PB',
    6: 'HB',
  };

  const result = val >= 1 && val <= 6 ? abbv[val] : '';
  return result;
};

export const mGymEvent = (val) => {
  const apparatus = {
    1: 'Floor Exercise',
    2: 'Pommel Horse',
    3: 'Still Rings',
    4: 'Vault',
    5: 'Parallel Bars',
    6: 'Horizontal Bar',
  };

  const result = val >= 1 && val <= 6 ? apparatus[val] : '';
  return result;
};

export const wGymAbbv = (val) => {
  const abbv = {
    1: 'VT',
    2: 'UB',
    3: 'BB',
    4: 'FX',
  };

  const result = val >= 1 && val <= 4 ? abbv[val] : '';
  return result;
};

export const wGymEvent = (val) => {
  const apparatus = {
    1: 'Vault',
    2: 'Uneven Bars',
    3: 'Balance Beam',
    4: 'Floor Exercise',
  };

  const result = val >= 1 && val <= 4 ? apparatus[val] : '';
  return result;
};

export const prettifyXml = (sourceXml) => {
  let xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
  let xsltDoc = new DOMParser().parseFromString(
    [
      // describes how we want to modify the XML - indent everything
      '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
      '  <xsl:strip-space elements="*"/>',
      '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
      '    <xsl:value-of select="normalize-space(.)"/>',
      '  </xsl:template>',
      '  <xsl:template match="node()|@*">',
      '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
      '  </xsl:template>',
      '  <xsl:output indent="yes"/>',
      '</xsl:stylesheet>',
    ].join('\n'),
    'application/xml'
  );

  let xsltProcessor = new XSLTProcessor();
  xsltProcessor.importStylesheet(xsltDoc);
  let resultDoc = xsltProcessor.transformToDocument(xmlDoc);
  let resultXml = new XMLSerializer().serializeToString(resultDoc);
  return resultXml;
};

// Saving File
export const saveToFile = (fileName, data) => {
  // create file in browser
  const blob = new Blob([data], { type: 'application/json' });
  const href = URL.createObjectURL(blob);

  // create "a" HTLM element with href to file
  const link = document.createElement('a');
  link.href = href;
  console.log(fileName);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
