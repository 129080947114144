export const ncaaHeadCoaches = {
  // using RTN ID as identifier

  MALE: {},

  FEMALE: {
    1: {
      headCoach: 'Jennifer Green',
      venue: 'Cadet West Gymnasium',
      location: 'Colorado Springs, CO',
      shortName: 'Air Force',
    },
    2: {
      headCoach: 'Ashley Johnston',
      venue: 'Coleman Coliseum',
      location: 'Tuscaloosa, AL',
      shortName: 'UA',
    },
    3: {
      headCoach: 'Marie-Sophie Boggasch',
      venue: 'Alaska Airlines Center',
      location: 'Anchorage, AK',
      shortName: 'UAF',
    },
    4: {
      headCoach: 'John Court',
      venue: 'McKale Center',
      location: 'Tucson, AZ',
      shortName: 'UA',
    },
    5: {
      headCoach: 'Jay Santos',
      venue: 'Desert Financial Arena',
      location: 'Tempe, AZ',
      shortName: 'ASU',
    },
    6: {
      headCoach: 'Jordyn Wieber',
      venue: 'Barnhill Arena',
      location: 'Fayetteville, AR',
      shortName: 'UARK',
    },
    7: {
      headCoach: 'Jeff Graba',
      venue: 'Neville Arena',
      location: 'Auburn, AL',
      shortName: 'AU',
    },
    8: {
      headCoach: 'Guard Young',
      venue: 'Marriott Center',
      location: 'Provo, UT',
      shortName: 'BYU',
    },
    9: {
      headCoach: 'Joanna Saleem',
      venue: 'Worthen Arena',
      location: 'Muncie, IN',
      shortName: 'BSU',
    },
    10: {
      headCoach: 'Tina Bird',
      venue: 'ExtraMile Arena',
      location: 'Boise, ID',
      shortName: 'BSU',
    },
    11: {
      headCoach: 'Brittney Emmons',
      venue: 'Anderson Arena',
      location: 'Bowling Green, OH',
      shortName: 'BGSU',
    },
    12: {
      headCoach: 'Lorraine Galow',
      venue: 'Wheeler Recreation Center',
      location: 'Bridgeport, CT',
      shortName: 'UB',
    },
    13: {
      headCoach: 'Rene Lyst',
      venue: 'Tuttle North Gymnastics Facility',
      location: 'Brockport, NY',
      shortName: 'Brockport',
    },
    14: {
      headCoach: 'Brittany Harris',
      venue: 'Pizzitola Sports Center',
      location: 'Providence, RI',
      shortName: 'Brown',
    },
    15: {
      headCoach: 'Liz Crandall-Howell / Justin Howell',
      venue: 'Haas Pavilion',
      location: 'Berkeley, CA',
      shortName: 'Cal',
    },
    16: {
      headCoach: 'Meg Crowley',
      venue: 'Gold Dome',
      location: 'Shreveport, LA',
      shortName: 'Centenary',
    },
    17: {
      headCoach: 'Christine MacDonald',
      venue: 'McGuirk Arena',
      location: 'Mount Pleasant, MI',
      shortName: 'CMU',
    },
    18: {
      headCoach: 'Melanie Hall',
      venue: 'Teagle Hall Gymnasium',
      location: 'Ithaca, NY',
      shortName: 'Cornell',
    },
    19: {
      headCoach: 'Sulekha Zaug',
      venue: 'Park Center Gymnasium',
      location: 'Cortland, NY',
      shortName: 'Cortland',
    },
    20: {
      headCoach: 'Melissa Kutcher-Rinehart',
      venue: 'Magness Arena',
      location: 'Denver, CO',
      shortName: 'DU',
    },
    21: {
      headCoach: 'Katie Minasola',
      venue: 'Bowen Field House',
      location: 'Ypsilanti, MI',
      shortName: 'EMU',
    },
    22: {
      headCoach: 'Jenny Rowland',
      venue: "Exactech Arena at Stephen C.O'Connell Center",
      location: 'Gainesville, FL',
      shortName: 'UF',
    },
    23: {
      headCoach: 'Stephanie Worrell',
      venue: 'Charles E.Smith Center',
      location: 'Washington, D.C.',
      shortName: 'GWU',
    },
    24: {
      headCoach: 'Cécile Canqueteau-Landi / Ryan Roberts',
      venue: 'Stegeman Coliseum',
      location: 'Athens, GA',
      shortName: 'UGA',
    },
    25: {
      headCoach: 'Aryn DeGrood',
      venue: 'Lund Center',
      location: 'St.Peter, MN',
      shortName: 'Gustavus',
    },
    26: {
      headCoach: 'Doug Byrnes',
      venue: 'Walker Fieldhouse',
      location: 'St.Paul, MN',
      shortName: 'Hamline',
    },
    27: {
      headCoach: 'Bob Conkling',
      venue: 'Horton Field House',
      location: 'Normal, IL',
      shortName: 'ISU',
    },
    28: {
      headCoach: 'Nadalie Walsh',
      venue: 'Huff Hall',
      location: 'Champaign, IL',
      shortName: 'UIUC',
    },
    29: {
      headCoach: 'Jen Llewellyn',
      venue: 'Carver-Hawkeye Arena',
      location: 'Iowa City, IA',
      shortName: 'Iowa',
    },
    30: {
      headCoach: 'Ashley Miles Greig',
      venue: 'Hilton Coliseum',
      location: 'Ames, IA',
      shortName: 'ISU',
    },
    31: {
      headCoach: 'Rick Suddaby',
      venue: 'Ben Light Gymnasium',
      location: 'Ithaca, NY',
      shortName: 'Ithaca',
    },
    32: {
      headCoach: 'Brice Biggin',
      venue: 'Memorial Athletic and Convocation Center',
      location: 'Kent, OH',
      shortName: 'KSU',
    },
    33: {
      headCoach: 'Tim Garrison',
      venue: 'Memorial Coliseum',
      location: 'Lexington, KY',
      shortName: 'UK',
    },
    34: {
      headCoach: 'Jay Clark',
      venue: 'Pete Maravich Assembly Center',
      location: 'Baton Rouge, LA',
      shortName: 'LSU',
    },
    36: {
      headCoach: 'Brett Nelligan',
      venue: 'XFINITY Center',
      location: 'College Park, MD',
      shortName: 'UMD',
    },
    37: {
      headCoach: 'Bev Plocki',
      venue: 'Crisler Center',
      location: 'Ann Arbor, MI',
      shortName: 'UMich',
    },
    38: {
      headCoach: 'Mike Rowe',
      venue: 'Jenison Field House',
      location: 'East Lansing, MI',
      shortName: 'MSU',
    },
    39: {
      headCoach: 'Jenny Hansen',
      venue: 'Maturi Pavilion',
      location: 'Minneapolis, MN',
      shortName: 'UMN',
    },
    40: {
      headCoach: 'Shannon Welker',
      venue: 'Hearnes Center',
      location: 'Columbia, MO',
      shortName: 'Mizzou',
    },
    41: {
      headCoach: 'Heather Brink',
      venue: 'Bob Devaney Sports Center',
      location: 'Lincoln, NE',
      shortName: 'Nebraska',
    },
    42: {
      headCoach: 'Lindsey Bruck Ayotte',
      venue: 'Lundholm Gymnasium',
      location: 'Durham, NH',
      shortName: 'UNH',
    },
    43: {
      headCoach: 'Danna Durante',
      venue: 'Carmichael Arena',
      location: 'Chapel Hill, NC',
      shortName: 'UNC',
    },
    44: {
      headCoach: 'Kim Landrus',
      venue: 'Reynolds Coliseum',
      location: 'Raleigh, NC',
      shortName: 'NC State',
    },
    45: {
      headCoach: 'Dawnita Teague',
      venue: 'Convocation Center',
      location: 'DeKalb, IL',
      shortName: 'NIU',
    },
    46: {
      headCoach: 'Meredith Paulicivic',
      venue: 'Covelli Center',
      location: 'Columbus, OH',
      shortName: 'OSU',
    },
    47: {
      headCoach: 'K.J.Kindler',
      venue: 'Lloyd Noble Center',
      location: 'Norman, OK',
      shortName: 'OU',
    },
    48: {
      headCoach: 'Tanya Chaplin',
      venue: 'Gill Coliseum',
      location: 'Corvallis, OR',
      shortName: 'OSU',
    },
    49: {
      headCoach: 'Sarah Brown',
      venue: 'Rec Hall',
      location: 'University Park, PA',
      shortName: 'PSU',
    },
    50: {
      headCoach: 'Kirsten Becker',
      venue: 'The Palestra',
      location: 'Philadelphia, PA',
      shortName: 'Penn',
    },
    51: {
      headCoach: 'Casey Jo MacPherson',
      venue: 'Fitzgerald Field House',
      location: 'Pittsburgh, PA',
      shortName: 'Pitt',
    },
    52: {
      headCoach: 'Monica Mesalles Nassi',
      venue: 'Murray Center',
      location: 'Providence, RI',
      shortName: 'RIC',
    },
    53: {
      headCoach: 'Anastasia Candia',
      venue: "Jersey Mike's Arena",
      location: 'Piscataway, NJ',
      shortName: 'Rutgers',
    },
    54: {
      headCoach: 'Ashley Lawson',
      venue: 'Houck Field House',
      location: 'Cape Girardeau, MO',
      shortName: 'SEMO',
    },
    55: {
      headCoach: 'Melissa Genovese',
      venue: 'The Nest',
      location: 'Sacramento, CA',
      shortName: 'Sac State',
    },
    56: {
      headCoach: 'Joanne Bowers',
      venue: 'Provident Credit Union Event Center',
      location: 'San Jose, CA',
      shortName: 'SJSU',
    },
    58: {
      headCoach: 'Jerry Nelson',
      venue: 'Moore Fieldhouse',
      location: 'New Haven, CT',
      shortName: 'SCSU',
    },
    59: {
      headCoach: 'Scott Bauman',
      venue: 'America First Event Center',
      location: 'Cedar City, UT',
      shortName: 'SUU',
    },
    60: {
      headCoach: 'Diana Gallagher',
      venue: 'Blake Arena',
      location: 'Springfield, MA',
      shortName: 'Springfield',
    },
    61: {
      headCoach: 'Tabitha Yim',
      venue: 'Maples Pavilion',
      location: 'Stanford, CA',
      shortName: 'Stanford',
    },
    62: {
      headCoach: 'Hilary Steele',
      venue: 'McGonigle Hall',
      location: 'Philadelphia, PA',
      shortName: 'Temple',
    },
    63: {
      headCoach: 'Lisa Bowerman',
      venue: 'Kitty Magee Arena',
      location: 'Denton, TX',
      shortName: 'TWU',
    },
    64: {
      headCoach: 'Jay Ramirez',
      venue: 'SECU Arena',
      location: 'Towson, MD',
      shortName: 'Towson',
    },
    65: {
      headCoach: 'Tanya Ho',
      venue: 'The Pavilion',
      location: 'Davis, CA',
      shortName: 'UC Davis',
    },
    66: {
      headCoach: 'Janelle McDonald',
      venue: 'Pauley Pavilion',
      location: 'Los Angeles, CA',
      shortName: 'UCLA',
    },
    68: {
      headCoach: 'Kim Valenti',
      venue: 'Helfferich Hall',
      location: 'Collegeville, PA',
      shortName: 'Ursinus',
    },
    69: {
      headCoach: 'Carly Dockendorf',
      venue: 'Jon M.Huntsman Center',
      location: 'Salt Lake City, UT',
      shortName: 'Utah',
    },
    70: {
      headCoach: 'Kristin White',
      venue: 'Wayne Estes Center',
      location: 'Logan, UT',
      shortName: 'USU',
    },
    71: {
      headCoach: 'Jessa Hansen Parker',
      venue: 'Alaska Airlines Arena',
      location: 'Seattle, WA',
      shortName: 'UW',
    },
    72: {
      headCoach: 'Barbara Cordova',
      venue: 'Hollinger Fieldhouse',
      location: 'West Chester, PA',
      shortName: 'WCU',
    },
    73: {
      headCoach: 'Jason Butts',
      venue: 'WVU Coliseum',
      location: 'Morgantown, WV',
      shortName: 'WVU',
    },
    74: {
      headCoach: 'Penny Jernigan',
      venue: 'University Arena',
      location: 'Kalamazoo, MI',
      shortName: 'WMU',
    },
    75: {
      headCoach: 'Kelsey Hinton',
      venue: 'Kaplan Arena',
      location: 'Williamsburg, VA',
      shortName: 'W & M',
    },
    76: {
      headCoach: 'Stephanie Geislinger',
      venue: 'McCown Gymnasium',
      location: 'Winona, MN',
      shortName: 'WSU',
    },
    77: {
      headCoach: 'Jessie DeZiel',
      venue: 'McPhee Physical Education Center',
      location: 'Eau Claire, WI',
      shortName: 'UWEC',
    },
    78: {
      headCoach: 'Kasey Crawford',
      venue: 'Mitchell Hall',
      location: 'La Crosse, WI',
      shortName: 'UWLC',
    },
    79: {
      headCoach: 'Lauren Karnitz',
      venue: 'Kolf Sports Center',
      location: 'Oshkosh, WI',
      shortName: 'UWO',
    },
    80: {
      headCoach: 'Kj Wheeler',
      venue: 'Johnson Fieldhouse',
      location: 'Menomonie, WI',
      shortName: 'UW-Stout',
    },
    81: {
      headCoach: 'Jennifer Regan',
      venue: 'Williams Center',
      location: 'Whitewater, WI',
      shortName: 'UWW',
    },
    82: {
      headCoach: 'Andrew Leis',
      venue: 'Payne Whitney Gymnasium',
      location: 'New Haven, CT',
      shortName: 'Yale',
    },
    128: {
      headCoach: 'Taylor Colwell',
      venue: 'Pratt Recreation Center',
      location: 'Brookville, NY',
      shortName: 'LIU',
    },
    154: {
      headCoach: 'Anique Grenier',
      venue: 'H.J.Long Gymnasium',
      location: 'Greenville, IL',
      shortName: 'Greenville',
    },
    155: {
      headCoach: 'Emily Barrett Payne',
      venue: 'Cowles Fieldhouse',
      location: 'Indianola, IA',
      shortName: 'Simpson',
    },
    156: {
      headCoach: 'Nuriya Mack',
      venue: 'Henderson A.Johnson Gymnasium',
      location: 'Nashville, TN',
      shortName: 'Fisk',
    },
    163: {
      headCoach: 'Amy Smith',
      venue: 'Jervey Gymnasium',
      location: 'Clemson, SC',
      shortName: 'Clemson',
    },
    164: {
      headCoach: 'Danielle Cerminaro',
      venue: 'Miga Court, Clark Athletic Center Gymnasium',
      location: 'Utica, NY',
      shortName: 'Utica',
    },
    174: {
      headCoach: 'Morgan Byrd',
      venue: 'Wilberforce Gymnasium',
      location: 'Wilberforce, OH',
      shortName: 'Wilberforce',
    },
  },
};
