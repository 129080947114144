import {
  adminConstants,
  sessionConstants,
  initialAdminState,
  initialScrollAndSearchState,
} from '../_constants';
import { combineReducers } from 'redux';

const initialDashboardFilters = {
  gender: null,
  status: null,
  start: null,
  demo: null,
  startDate: null,
  endDate: null,
};

const initialDashboardSort = [{ id: 'start', desc: true }];

function status(state = { 
  ...initialAdminState, 
  dashboardFilters: initialDashboardFilters,
  dashboardSort: initialDashboardSort
}, action) {
  switch (action.type) {
    case adminConstants.GET_TEAM_ATHLETES_SUCCESS:
    case adminConstants.GET_TEAM_ROSTER_ATHLETES_SUCCESS:
    case adminConstants.GET_ROSTER_ATHLETES_SUCCESS:
      return {
        ...state,
        filter: action.data.teamAthletes,
        filterId: action.data.id,
      };
    case adminConstants.UPDATE_TABLE_DATA:
      return {
        ...state,
        table: {
          newData: action.data.newData
            ? action.data.newData
            : state.table.newData,
          newSelected: action.data.newSelected
            ? action.data.newSelected
            : state.table.newSelected,
          initialData: action.data.initialData
            ? action.data.initialData
            : state.table.initialData,
          initialSelected: action.data.initialSelected
            ? action.data.initialSelected
            : state.table.initialSelected,
        },
      };
    case adminConstants.SET_DASHBOARD_FILTERS:
      return {
        ...state,
        dashboardFilters: action.filters
      };
    case adminConstants.SET_DASHBOARD_SORT:
      return {
        ...state,
        dashboardSort: action.sort
      };
    default:
      return state;
  }
}

function coachesById(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_TEAMS_SUCCESS:
      if (action.data.coaches && action.data.coaches.entities) {
        return {
          ...state, // handles the empty case
          ...action.data.coaches.entities.coaches,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}

function rosterLinksById(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_TEAMS_SUCCESS:
      if (
        action.data.rosterLinks &&
        action.data.rosterLinks.entities &&
        action.data.rosterLinks.entities.rosterLinks
      ) {
        return {
          ...state,
          ...action.data.rosterLinks.entities.rosterLinks,
        };
      } else {
        return state;
      }
    case adminConstants.EDIT_ROSTER_SUCCESS:
      return action.data.rosterLinks.byId;
    default:
      return state;
  }
}

function rostersById(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_TEAMS_SUCCESS:
      if (action.data.rosters && action.data.rosters.entities) {
        return {
          ...state, // handles the empty case
          ...action.data.rosters.entities.rosters,
        };
      } else {
        return state;
      }
    case adminConstants.GET_ROSTERS_SUCCESS:
      return {
        ...state,
        ...action.data.rosters.entities.rosters,
      };
    case adminConstants.EDIT_ROSTER_SUCCESS:
      return {
        ...state,
        [action.data.roster.id]: action.data.roster,
      };
    case adminConstants.CREATE_ROSTER_SUCCESS:
      return action.data.byId;
    default:
      return state;
  }
}

function lineupsById(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_TEAMS_SUCCESS:
    case adminConstants.GET_SESSION_SUCCESS:
    case adminConstants.GET_SESSIONS_SUCCESS:
      if (action.data.lineups && action.data.lineups.entities) {
        return {
          ...state, // handles the empty case
          ...action.data.lineups.entities.lineups,
        };
      } else {
        return state;
      }
    case adminConstants.GET_LINEUPS_SUCCESS:
      return {
        ...state, // handles the empty case
        ...action.data.lineups.entities.lineups,
      };
    case adminConstants.UPDATE_LINEUP_TITLES_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}

function usersById(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_USERS_SUCCESS:
      return {
        ...state,
        ...action.data.users.entities.users,
      };
    case adminConstants.CREATE_USER_SUCCESS:
    case adminConstants.EDIT_USER_SUCCESS:
      return action.data.byId;
    default:
      return state;
  }
}

function judgesById(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_JUDGES_SUCCESS:
      return {
        ...state,
        ...action.data.judges.entities.users,
      };
    default:
      return state;
  }
}

function teamsById(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_SESSION_SUCCESS:
    case adminConstants.GET_SESSIONS_SUCCESS:
    case adminConstants.GET_TEAMS_SUCCESS:
      return {
        ...state, // handles the empty case
        ...action.data.teams?.entities?.teams,
      };
    case adminConstants.CREATE_TEAM_SUCCESS:
    case adminConstants.EDIT_TEAM_SUCCESS:
      return action.data.byId;
    case adminConstants.CREATE_ROSTER_SUCCESS:
      return {
        ...state,
        [action.data.roster.teamId]: {
          ...state[action.data.roster.teamId],
          rosters: {
            items: [
              ...state[action.data.roster.teamId].rosters.items,
              action.data.roster.id,
            ],
          },
        },
      };
    default:
      return state;
  }
}

function leaguesById(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_LEAGUES_SUCCESS:
      return {
        ...state, // handles the empty case
        ...action.data.leagues.entities.leagues,
      };
    case adminConstants.CREATE_LEAGUE_SUCCESS:
    case adminConstants.EDIT_LEAGUE_SUCCESS:
      return action.data.byId;
    default:
      return state;
  }
}

function sessionsById(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_SESSION_SUCCESS:
    case adminConstants.GET_SESSIONS_SUCCESS:
      return {
        ...state,
        ...action.data.sessions.entities.sessions,
      };
    case adminConstants.CREATE_SESSION_SUCCESS:
    case adminConstants.EDIT_SESSION_SUCCESS:
      return {
        ...state,
        ...action.data.byId
      };
    case adminConstants.UPDATE_SESSION_JUDGE_SUCCESS:
      return action.data.sessions;
    default:
      return state;
  }
}

function sessionTeamsById(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_SESSION_SUCCESS:
    case adminConstants.GET_SESSIONS_SUCCESS:
      if (
        action.data.sessionTeams &&
        action.data.sessionTeams.entities &&
        action.data.sessionTeams.entities.sessionTeams
      ) {
        return {
          ...state,
          ...action.data.sessionTeams.entities.sessionTeams,
        };
      } else {
        return state;
      }
    case adminConstants.CREATE_SESSION_SUCCESS:
    case adminConstants.EDIT_SESSION_SUCCESS:
      return action.data.sessionTeams.byId;
    default:
      return state;
  }
}

function sessionJudgesById(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_SESSION_SUCCESS:
    case adminConstants.GET_SESSIONS_SUCCESS:
      if (
        action.data.sessionJudges &&
        action.data.sessionJudges.entities &&
        action.data.sessionJudges.entities.sessionJudges
      ) {
        return {
          ...state,
          ...action.data.sessionJudges.entities.sessionJudges,
        };
      } else {
        return state;
      }
    case adminConstants.UPDATE_SESSION_JUDGE_SUCCESS:
      return action.data.sessionJudges?.byId;
    case adminConstants.CREATE_SESSION_SUCCESS:
    case adminConstants.EDIT_SESSION_SUCCESS:
    default:
      return state;
  }
}

function streamsById(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_SESSION_SUCCESS:
    case adminConstants.GET_SESSIONS_SUCCESS:
      if (
        action.data.streams &&
        action.data.streams.entities &&
        action.data.streams.entities.streams
      ) {
        return {
          ...state,
          ...action.data.streams.entities.streams,
        };
      } else {
        return state;
      }
    case adminConstants.UPDATE_STREAM_URLS_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case sessionConstants.CREATE_STREAM_NOCAM_SUCCESS:
      return {
        ...state,
        [action.stream.id]: action.stream,
      };
    default:
      return state;
  }
}

function athletesById(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_ATHLETES_SUCCESS:
      return {
        ...state,
        ...action.data.athletes.entities.athletes,
      };
    case adminConstants.GET_TEAM_ATHLETES_SUCCESS:
    case adminConstants.GET_ROSTER_ATHLETES_SUCCESS:
    case adminConstants.GET_TEAM_ROSTER_ATHLETES_SUCCESS:
      if (action.data.hasOwnProperty('athletes')) {
        return {
          ...state,
          ...action.data.athletes.entities.athletes,
        };
      } else {
        return state;
      }
    case adminConstants.EDIT_ATHLETE_SUCCESS:
    case adminConstants.CREATE_ATHLETE_SUCCESS:
      return action.data.byId;
    default:
      return state;
  }
}

function allCoaches(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_TEAMS_SUCCESS:
      if (action.data.coaches && action.data.coaches.result) {
        return action.data.coaches.result;
      } else {
        return state;
      }
    case adminConstants.SORT_NAME_DESC:
    case adminConstants.SORT_NAME_ASC:
      return action.result;
    default:
      return state;
  }
}

function allRosterLinks(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_TEAMS_SUCCESS:
      if (action.data.rosterLinks && action.data.rosterLinks.result) {
        return action.data.rosterLinks.result;
      } else {
        return state;
      }
    case adminConstants.EDIT_ROSTER_SUCCESS:
      return action.data.rosterLinks.allIds;
    default:
      return state;
  }
}

function allRosters(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_TEAMS_SUCCESS:
      if (action.data.rosters && action.data.rosters.result) {
        return action.data.rosters.result;
      } else {
        return state;
      }
    case adminConstants.SORT_NAME_DESC:
    case adminConstants.SORT_NAME_ASC:
      return action.result;
    case adminConstants.CREATE_ROSTER_SUCCESS:
      return action.data.allIds;
    default:
      return state;
  }
}

function allLineups(state = [], action) {
  switch (action.type) {
    case adminConstants.UPDATE_LINEUP_TITLES_SUCCESS:
    case adminConstants.GET_SESSION_SUCCESS:
    case adminConstants.GET_TEAMS_SUCCESS:
      if (action.data.lineups && action.data.lineups.result) {
        return action.data.lineups.result;
      } else {
        return state;
      }
    case adminConstants.SORT_NAME_DESC:
    case adminConstants.SORT_NAME_ASC:
      return action.result;
    default:
      return state;
  }
}

function allUsers(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_USERS_SUCCESS:
      return action.data.users.result;
    case adminConstants.CREATE_USER_SUCCESS:
      return action.data.allIds;
    case adminConstants.SORT_NAME_DESC:
    case adminConstants.SORT_NAME_ASC:
    case adminConstants.SORT_CREATED_DESC:
    case adminConstants.SORT_CREATED_ASC:
      return action.result;
    default:
      return state;
  }
}

function allJudges(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_JUDGES_SUCCESS:
      return action.data.judges.result;
    case adminConstants.SORT_NAME_DESC:
    case adminConstants.SORT_NAME_ASC:
    case adminConstants.SORT_CREATED_DESC:
    case adminConstants.SORT_CREATED_ASC:
      return action.result;
    default:
      return state;
  }
}

function allTeams(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_TEAMS_SUCCESS:
    case adminConstants.GET_SESSION_SUCCESS:
    case adminConstants.GET_SESSIONS_SUCCESS:
      return action.data.teams.result;
    case adminConstants.CREATE_TEAM_SUCCESS:
      return action.data.allIds;
    case adminConstants.SORT_NAME_DESC:
    case adminConstants.SORT_NAME_ASC:
      return action.result;
    default:
      return state;
  }
}

function allLeagues(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_LEAGUES_SUCCESS:
      return action.data.leagues.result;
    case adminConstants.CREATE_LEAGUE_SUCCESS:
      return action.data.allIds;
    case adminConstants.SORT_NAME_DESC:
    case adminConstants.SORT_NAME_ASC:
      return action.result;
    default:
      return state;
  }
}

function allSessions(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_SESSION_SUCCESS:
    case adminConstants.GET_SESSIONS_SUCCESS:
      return action.data.sessions.result;
    case adminConstants.CREATE_SESSION_SUCCESS:
      return action.data.allIds;
    case adminConstants.SORT_CREATED_DESC:
    case adminConstants.SORT_CREATED_ASC:
    case adminConstants.SORT_NAME_DESC:
    case adminConstants.SORT_NAME_ASC:
      return action.result;
    default:
      return state;
  }
}

function allSessionTeams(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_SESSION_SUCCESS:
    case adminConstants.GET_SESSIONS_SUCCESS:
      if (action.data.sessionTeams && action.data.sessionTeams.result) {
        return action.data.sessionTeams.result;
      } else {
        return state;
      }
    case adminConstants.CREATE_SESSION_SUCCESS:
    case adminConstants.EDIT_SESSION_SUCCESS:
      return action.data.sessionTeams.allIds;
    case adminConstants.SORT_CREATED_DESC:
    case adminConstants.SORT_CREATED_ASC:
    case adminConstants.SORT_NAME_DESC:
    case adminConstants.SORT_NAME_ASC:
      return action.result;
    default:
      return state;
  }
}

function allSessionJudges(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_SESSION_SUCCESS:
    case adminConstants.GET_SESSIONS_SUCCESS:
      if (action.data.sessionJudges && action.data.sessionJudges.result) {
        // Merge new session judges with existing ones, avoiding duplicates
        return [...new Set([...state, ...action.data.sessionJudges.result])];
      } else {
        return state;
      }
    case adminConstants.SORT_CREATED_DESC:
    case adminConstants.SORT_CREATED_ASC:
    case adminConstants.SORT_NAME_DESC:
    case adminConstants.SORT_NAME_ASC:
      return action.result;
    case adminConstants.UPDATE_SESSION_JUDGE_SUCCESS:
      return action.data.sessionJudges.allIds;
    case adminConstants.CREATE_SESSION_SUCCESS:
    case adminConstants.EDIT_SESSION_SUCCESS:
    default:
      return state;
  }
}

function allStreams(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_SESSIONS_SUCCESS:
      if (action.data.streams && action.data.streams.result) {
        return action.data.streams.result;
      } else {
        return state;
      }
    case sessionConstants.CREATE_STREAM_NOCAM_SUCCESS:
      return [...state, action.stream.id];
    default:
      return state;
  }
}

function allAthletes(state = [], action) {
  switch (action.type) {
    case adminConstants.GET_ATHLETES_SUCCESS:
      return action.data.athletes.result;
    case adminConstants.CREATE_ATHLETE_SUCCESS:
      return action.data.allIds;
    case adminConstants.GET_TEAM_ATHLETES_SUCCESS:
    case adminConstants.GET_ROSTER_ATHLETES_SUCCESS:
    case adminConstants.GET_TEAM_ROSTER_ATHLETES_SUCCESS:
      if (action.data.hasOwnProperty('athletes')) {
        return action.data.athletes.result;
      } else {
        return state;
      }
    case adminConstants.SORT_CREATED_DESC:
    case adminConstants.SORT_CREATED_ASC:
    case adminConstants.SORT_NAME_DESC:
    case adminConstants.SORT_NAME_ASC:
      return action.result;
    default:
      return state;
  }
}

function athleteTokens(state = initialScrollAndSearchState, action) {
  switch (action.type) {
    case adminConstants.GET_ATHLETES_SUCCESS:
      return action.data.tokens;
    default:
      return state;
  }
}

function userTokens(state = initialScrollAndSearchState, action) {
  switch (action.type) {
    case adminConstants.GET_USERS_SUCCESS:
      return action.data.tokens;
    default:
      return state;
  }
}

function judgeTokens(state = initialScrollAndSearchState, action) {
  switch (action.type) {
    case adminConstants.GET_JUDGES_SUCCESS:
      return action.data.tokens;
    default:
      return state;
  }
}

function sessionTokens(state = initialScrollAndSearchState, action) {
  switch (action.type) {
    case adminConstants.GET_SESSIONS_SUCCESS:
      return action.data.tokens;
    default:
      return state;
  }
}

function teamTokens(state = initialScrollAndSearchState, action) {
  switch (action.type) {
    case adminConstants.GET_TEAMS_SUCCESS:
      return action.data.tokens;
    default:
      return state;
  }
}

function leagueTokens(state = initialScrollAndSearchState, action) {
  switch (action.type) {
    case adminConstants.GET_LEAGUES_SUCCESS:
      return action.data.tokens;
    default:
      return state;
  }
}

const leagues = combineReducers({
  byId: leaguesById,
  allIds: allLeagues,
  tokens: leagueTokens,
});

const teams = combineReducers({
  byId: teamsById,
  allIds: allTeams,
  tokens: teamTokens,
});

const sessions = combineReducers({
  byId: sessionsById,
  allIds: allSessions,
  tokens: sessionTokens,
});

const athletes = combineReducers({
  byId: athletesById,
  allIds: allAthletes,
  tokens: athleteTokens,
});

const judges = combineReducers({
  byId: judgesById,
  allIds: allJudges,
  tokens: judgeTokens,
});

const users = combineReducers({
  byId: usersById,
  allIds: allUsers,
  tokens: userTokens,
});

const rosters = combineReducers({
  byId: rostersById,
  allIds: allRosters,
});

const lineups = combineReducers({
  byId: lineupsById,
  allIds: allLineups,
});

const coaches = combineReducers({
  byId: coachesById,
  allIds: allCoaches,
});

const rosterLinks = combineReducers({
  byId: rosterLinksById,
  allIds: allRosterLinks,
});

const sessionTeams = combineReducers({
  byId: sessionTeamsById,
  allIds: allSessionTeams,
});

const streams = combineReducers({
  byId: streamsById,
  allIds: allStreams,
});

const sessionJudges = combineReducers({
  byId: sessionJudgesById,
  allIds: allSessionJudges,
});

export const admin = combineReducers({
  status: status,
  teams: teams,
  leagues: leagues,
  sessions: sessions,
  athletes: athletes,
  judges: judges,
  users: users,
  rosters: rosters,
  lineups: lineups,
  coaches: coaches,
  rosterLinks: rosterLinks,
  sessionTeams: sessionTeams,
  streams: streams,
  sessionJudges: sessionJudges,
});
